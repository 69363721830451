import type * as Types from '@leaf/types'

import { getLocation } from '@/features/contract'
import type { GetExecutionLaneDetailsQuery } from '@/features/execution-lane/execution-lane-details.api.generated'
import type { GetExecutionLaneOverviewQuery } from '@/features/execution-lane/execution-lane-overview.api.generated'

type ExecutionLaneType =
  | GetExecutionLaneDetailsQuery['executionLanesByPk']
  | GetExecutionLaneOverviewQuery['executionLanes'][0]

type ExecutionLaneMappedType = {
  geo?: GeoJSON.Feature | null
} & ReturnType<typeof mapExecutionLane>

const mapExecutionLane = (executionLane: Types.NoUndefined<ExecutionLaneType>) => {
  return {
    ...executionLane,
    co2Reduction: executionLane.co2ReductionPerShipment
      ? executionLane.co2ReductionPerShipment.toFixed()
      : null,
    destinationName: getLocation(executionLane.destination),
    destinationNameFlattened: `${executionLane.destination.shipperLocationName ?? executionLane.destination.shipperLocationReference ?? executionLane.destination.businessName ?? '-'} //
      ${executionLane.destination.street} // ${executionLane.destination.city}, ${executionLane.destination.state} ${executionLane.destination.zip}`,
    drivingTime: executionLane.drivingTimeMinutes
      ? (executionLane.drivingTimeMinutes / 60).toFixed()
      : null,
    originName: getLocation(executionLane.origin),
    originNameFlattened: `${executionLane.origin.shipperLocationName ?? executionLane.origin.shipperLocationReference ?? executionLane.origin.businessName ?? '-'} //
     ${executionLane.origin.street} // ${executionLane.origin.city}, ${executionLane.origin.state} ${executionLane.origin.zip}`,
  }
}

export { mapExecutionLane }
export type { ExecutionLaneMappedType }
