import type * as Types from '@leaf/types'

import { getLocation } from '@/features/contract'
import type { GetExecutionLocationDetailsQuery } from '@/features/execution-location/execution-location-details.api.generated'
import type { GetExecutionLocationOverviewQuery } from '@/features/execution-location/execution-location-overview.api.generated'

type ExecutionLocationType =
  | GetExecutionLocationDetailsQuery['executionLocationByPk']
  | GetExecutionLocationOverviewQuery['executionLocation'][0]

type ExecutionLocationMappedType = {
  geo?: GeoJSON.Feature | null
} & ReturnType<typeof mapExecutionLocation>

const mapExecutionLocation = (executionLocation: Types.NoUndefined<ExecutionLocationType>) => {
  return {
    ...executionLocation,
    address: getLocation(executionLocation),
  }
}

export { mapExecutionLocation }
export type { ExecutionLocationMappedType }
