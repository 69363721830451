import { useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Button, Card, CardContent, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui'
import { Marker } from 'react-map-gl'
import * as z from 'zod'

import { DataRow, Map, Page, Progress } from '@leaf/components'
import { clean, validation as v } from '@leaf/form'
import { time } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import {
  type ExecutionLocationMappedType,
  mapExecutionLocation,
} from '@/features/execution-location/execution-location.mapper'
import { executionLocationDetailsQuery } from '@/features/execution-location/execution-location-details.api'
import { useUpdateExecutionLocationMutation } from '@/features/execution-location/execution-location-details.api.generated'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const schema = z.object({
  accessRequirements: v.__string,
  appointmentRequirements: v.__string,
  contactEmail: v.__string,
  contactName: v.__string,
  contactPhone: v.__string,
  locationType: v.__string,
  operatingHours: v.__string,
  shipperLocationName: v.__string,
  shipperLocationReference: v.__string,
  trailerPoolStorageRequirements: v.__string,
})
type Schema = z.infer<typeof schema>

const ExecutionLocationDetails = () => {
  const { id } = useParams()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const { data } = useQuery(executionLocationDetailsQuery(id!))
  const executionLocation = mapExecutionLocation(data!) as ExecutionLocationMappedType

  const { mutate: update } = useUpdateExecutionLocationMutation(graphqlClient, {
    onSuccess: () => addSnackbar({ message: 'Execution location updated successfully' }),
  })

  const context = useForm<Schema>({
    defaultValues: executionLocation,
    mode: 'all',
    resolver: zr(schema),
  })

  const onSubmit = (values: Schema) => {
    update({ executionLocation: clean(values), id: executionLocation.id })
  }

  useTitles([
    { to: 'execution/locations', value: 'Execution Locations' },
    { value: executionLocation.id },
  ])
  useActions([
    <Button
      color='primary'
      form='execution-location-details-form'
      key='saveExecutionLocation'
      sx={{ marginRight: '1em' }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,
  ])

  return (
    <FormContainer
      FormProps={{ id: 'execution-location-details-form' }}
      formContext={context}
      onSuccess={onSubmit}
    >
      <Progress />

      <Page>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DataRow label='Company' value={executionLocation.company.name} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Business Name' value={executionLocation.businessName} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Street Address' value={executionLocation.street} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='City/State/Zip'
                    value={`${executionLocation.city}, ${executionLocation.state} ${executionLocation.zip}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Location Name'
                    name='shipperLocationName'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Location Reference'
                    name='shipperLocationReference'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectElement
                    fullWidth
                    label='Location Type'
                    name='locationType'
                    options={[
                      { id: 'CUSTOMER', label: 'Customer' },
                      { id: 'DISTRIBUTION_CENTER', label: 'Distribution Center' },
                      { id: 'FACTORY', label: 'Factory' },
                      { id: 'POOL', label: 'Pool' },
                      { id: 'SUPPLIER', label: 'Supplier' },
                      { id: 'WAREHOUSE', label: 'Warehouse' },
                    ]}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Created At'
                    value={time.toLocalDatetime(executionLocation.createdAt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Updated At'
                    value={time.toLocalDatetime(executionLocation.lastUpdatedAt)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Map
            // TODO: Enhance Leaf Map wrapper component to support automatic bounds fitting for a single Point. Or allow overriding initialViewState
            fitBounds={[
              {
                coordinates: [
                  [
                    executionLocation.geo.coordinates[0] - 0.1,
                    executionLocation.geo.coordinates[1] - 0.1,
                  ],
                  [
                    executionLocation.geo.coordinates[0] + 0.1,
                    executionLocation.geo.coordinates[1] + 0.1,
                  ],
                ],
                type: 'LineString',
              },
            ]}
            mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
          >
            <Marker
              latitude={executionLocation.geo.coordinates[1]}
              longitude={executionLocation.geo.coordinates[0]}
              pitchAlignment='map'
            />
          </Map>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Contact Name'
                    name='contactName'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Contact Email'
                    name='contactEmail'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Contact Phone'
                    name='contactPhone'
                    variant='standard'
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Operating Hours'
                    multiline
                    name='operatingHours'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Access Requirements'
                    multiline
                    name='accessRequirements'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Appointment Requirements'
                    multiline
                    name='appointmentRequirements'
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Trailer Pool Storage Requirements'
                    multiline
                    name='trailerPoolStorageRequirements'
                    variant='standard'
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Page>
    </FormContainer>
  )
}

export { ExecutionLocationDetails }
