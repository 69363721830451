import {
  Business as AccountsIcon,
  Explore as AdaptIcon,
  Lightbulb as AnalyticsIcon,
  Apps as AppsIcon,
  Notes as ChangelogIcon,
  AssignmentTurnedIn as ContractsIcon,
  Dashboard as DashboardIcon,
  ExitToApp as LogoutIcon,
  PendingActions as PlanningIcon,
  Factory as SettingsIcon,
  LocalShipping as ShippingIcon,
  Construction as ToolsIcon,
} from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { Drawer } from '@leaf/drawer'
import type * as Types from '@leaf/types'
import { noop } from '@leaf/utilities'

import { auth, restClient } from '@/api'
import { useStore } from '@/store'

const getItems = (dashboards: { category: string }[], toggleChangelog: Types.Noop) => {
  return [
    ...(dashboards.length > 1
      ? [
          {
            icon: DashboardIcon,
            subs: dashboards.map((d) => {
              return {
                text: d?.category,
                to: `/dashboard/${d.category}`,
              }
            }),
            text: 'dashboard',
            to: '/dashboard',
          },
        ]
      : [
          {
            icon: DashboardIcon,
            text: 'dashboard',
            to: '/dashboard',
          },
        ]),
    {
      icon: AccountsIcon,
      id: 'e2e-accounts-menu',
      subs: [
        { id: 'e2e-companies-menu-item', text: 'Companies', to: '/companies' },
        { text: 'Users', to: '/users' },
      ],
      text: 'accounts',
    },
    {
      icon: AdaptIcon,
      subs: [
        { text: 'Lanes', to: '/adapt/lanes' },
        { text: 'Network Moves', to: '/adapt/network-moves' },
        { text: 'Constellations', to: '/adapt/constellations' },
      ],
      text: 'adapt',
    },
    {
      icon: AnalyticsIcon,
      subs: [{ text: 'Lanes', to: '/analytics/lanes' }],
      text: 'analytics',
    },
    {
      icon: PlanningIcon,
      subs: [
        { text: 'Plans', to: '/planning/plans' },
        { text: 'Lanes', to: '/planning/lanes' },
      ],
      text: 'planning',
    },
    {
      icon: ContractsIcon,
      subs: [
        { text: 'Contracts', to: '/contracts' },
        { text: 'Contract Matching', to: '/contracts/matching' },
      ],
      text: 'contracting',
    },
    {
      icon: ShippingIcon,
      subs: [
        { text: 'Shipments', to: '/execution/shipments' },
        { text: 'Lanes', to: '/execution/lanes' },
        { text: 'Locations', to: '/execution/locations' },
        { text: 'Fuel Prices', to: '/execution/fuel-prices' },
        { text: 'Fuel Price Index', to: '/execution/fuel-price-index' },
      ],
      text: 'execution',
    },
    {
      icon: ToolsIcon,
      subs: [
        { text: 'PC*Miler', to: '/tools/pcm' },
        { text: 'Shipper Network', to: '/tools/shipper-network' },
        { text: 'Contract Network', to: '/tools/contract-network' },
      ],
      text: 'tools',
    },
    {
      icon: SettingsIcon,
      subs: [
        { text: 'Equipment Types', to: '/equipment-types' },
        { text: 'Fuel Surcharge Schedules', to: '/fuel-surcharge-schedules' },
      ],
      text: 'settings',
    },
    {
      icon: AppsIcon,
      subs: [
        { external: true, text: 'Carrier', to: 'https://carrier.leaflogistics.com' },
        {
          external: true,
          text: 'Graph Explorer',
          to: 'https://graph.leaflogistics.com/',
        },
        {
          external: true,
          text: 'Price Explorer',
          to: 'https://price.leaflogistics.com/',
        },
        { external: true, text: 'Shipper', to: 'https://shipper.leaflogistics.com' },
        { external: true, text: 'Tender', to: 'https://tender.leaflogistics.com' },
        {
          external: true,
          text: 'Upload',
          to: 'https://upload.leaflogistics.com/',
        },
      ],
      text: 'apps',
    },
    {
      icon: ChangelogIcon,
      onClick: toggleChangelog,
      text: 'changelog',
    },
    {
      icon: LogoutIcon,
      onClick: async () => {
        const options = {
          logoutParams: {
            returnTo: window.location.origin,
          },
        }
        await auth.client!.logout(options)
      },
      text: 'logout',
    },
  ]
}

const Navigation = ({
  // Default is noop since it's used in routing as well
  toggleChangelog = noop,
}: {
  toggleChangelog?: Types.Noop
}) => {
  const [showDrawer, toggleDrawer] = useStore(
    useShallow((state) => [state.showDrawer, state.toggleDrawer]),
  )
  const { data, isLoading } = useQuery({
    queryFn: () => restClient.get('tendering/holistics'),
    queryKey: ['holistics'],
  })

  const items = getItems(isLoading ? [] : data?.data, toggleChangelog)

  return <Drawer items={items} show={showDrawer} title='leaf' toggle={toggleDrawer} />
}

export { Navigation }
