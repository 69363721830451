import { useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Button, Card, CardContent, Grid, Link } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import * as z from 'zod'

import { DataRow, Map, Page, Progress, Route, runAnimation } from '@leaf/components'
import { clean, validation as v } from '@leaf/form'
import { color, time } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import {
  type ExecutionLaneMappedType,
  mapExecutionLane,
} from '@/features/execution-lane/execution-lane.mapper'
import { executionLaneDetailsQuery } from '@/features/execution-lane/execution-lane-details.api'
import { useUpdateExecutionLaneMutation } from '@/features/execution-lane/execution-lane-details.api.generated'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const schema = z.object({
  co2ReductionPerShipment: v.__number,
  referenceRpm: v.__number,
  shipperDistance: v.__number,
})
type Schema = z.infer<typeof schema>

const ExecutionLaneDetails = () => {
  const { id } = useParams()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const { data } = useQuery(executionLaneDetailsQuery(id!))
  const executionLane = mapExecutionLane(data!) as ExecutionLaneMappedType

  const { mutate: update } = useUpdateExecutionLaneMutation(graphqlClient, {
    onSuccess: () => addSnackbar({ message: 'Execution lane updated successfully' }),
  })

  const context = useForm<Schema>({
    defaultValues: executionLane,
    mode: 'all',
    resolver: zr(schema),
  })

  const onSubmit = (values: Schema) => {
    update({ executionLane: clean(values), id: executionLane.id })
  }

  useTitles([{ to: 'execution/lanes', value: 'Execution Lanes' }, { value: executionLane.id }])
  useActions([
    <Button
      color='primary'
      form='execution-lane-details-form'
      key='saveExecutionLane'
      sx={{ marginRight: '1em' }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,
  ])

  const geometry = {
    coordinates: executionLane.geo?.geometry?.coordinates[0],
    type: 'LineString' as const,
  }
  const onLoad = runAnimation(['lane-layer-0-dash'])

  return (
    <FormContainer
      FormProps={{ id: 'execution-lane-details-form' }}
      formContext={context}
      onSuccess={onSubmit}
    >
      <Progress />

      <Page>
        <Grid item xs={5}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DataRow label='Shipper' value={executionLane.shipper.name} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    jsx
                    label='Origin'
                    value={
                      <Link
                        href={`/execution/locations/${executionLane.origin.id}`}
                        target='_blank'
                        underline='none'
                      >
                        {executionLane.originNameFlattened}
                      </Link>
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    jsx
                    label='Destination'
                    value={
                      <Link
                        href={`/execution/locations/${executionLane.destination.id}`}
                        target='_blank'
                        underline='none'
                      >
                        {executionLane.destinationNameFlattened}
                      </Link>
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Leaf Distance' value={executionLane.leafDistance} />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Shipper Distance'
                    name='shipperDistance'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='Reference RPM'
                    name='referenceRpm'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow label='Tolls' value={executionLane.tolls} />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Driving Time'
                    value={executionLane.drivingTime ? `${executionLane.drivingTime} hrs` : null}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldElement
                    autoComplete='off'
                    fullWidth
                    label='CO2 Reduction (per shipment)'
                    name='co2ReductionPerShipment'
                    type='number'
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Created At'
                    value={time.toLocalDatetime(executionLane.createdAt)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DataRow
                    label='Updated At'
                    value={time.toLocalDatetime(executionLane.lastUpdatedAt)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={7}>
          {geometry?.coordinates && (
            <Map
              fitBounds={[geometry]}
              mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
              onLoad={onLoad}
            >
              <Route.MapLane
                animated
                color={color.lane(0)}
                geometry={geometry}
                id='0'
                position={0}
              />
            </Map>
          )}
        </Grid>
      </Page>
    </FormContainer>
  )
}

export { ExecutionLaneDetails }
