import { useParams } from 'react-router-dom'

import { LaneExplorer } from '@leaf/lane-explorer'

import { graphqlClient, queryClient } from '@/api'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const LaneExplorerView = () => {
  const { id } = useParams()

  const user = useStore((state) => state.user)
  const addSnackbar = useStore((state) => state.addSnackbar)

  useTitles([{ to: 'adapt/lanes', value: 'Adapt Lanes' }, { value: id! }])

  return (
    <LaneExplorer
      addSnackbar={addSnackbar}
      graphqlClient={graphqlClient}
      mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
      queryClient={queryClient}
      user={user}
    />
  )
}

export { LaneExplorerView }
