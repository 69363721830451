// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetExecutionLocationOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetExecutionLocationOverviewQuery = { __typename?: 'query_root', executionLocation: Array<{ __typename?: 'ExecutionLocation', id: number, businessName?: string, street?: string, city?: string, state?: string, zip?: string, country?: string, timezone?: string, geo?: any, shipperLocationName?: string, shipperLocationReference?: string, locationType?: string, contactName?: string, contactEmail?: string, contactPhone?: string, operatingHours?: string, accessRequirements?: string, appointmentRequirements?: string, trailerPoolStorageRequirements?: string, createdAt?: any, lastUpdatedAt?: any, company: { __typename?: 'companies', id: any, name: string } }> };



export const GetExecutionLocationOverviewDocument = `
    query GetExecutionLocationOverview @cached(ttl: 300) {
  executionLocation(orderBy: {lastUpdatedAt: DESC}) {
    id
    company {
      id
      name
    }
    businessName
    street
    city
    state
    zip
    country
    timezone
    geo
    shipperLocationName
    shipperLocationReference
    locationType
    contactName
    contactEmail
    contactPhone
    operatingHours
    accessRequirements
    appointmentRequirements
    trailerPoolStorageRequirements
    createdAt
    lastUpdatedAt
  }
}
    `;

export const useGetExecutionLocationOverviewQuery = <
      TData = GetExecutionLocationOverviewQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetExecutionLocationOverviewQueryVariables,
      options?: UseQueryOptions<GetExecutionLocationOverviewQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetExecutionLocationOverviewQuery, TError, TData>(
      variables === undefined ? ['GetExecutionLocationOverview'] : ['GetExecutionLocationOverview', variables],
      fetcher<GetExecutionLocationOverviewQuery, GetExecutionLocationOverviewQueryVariables>(client, GetExecutionLocationOverviewDocument, variables, headers),
      options
    )};

useGetExecutionLocationOverviewQuery.document = GetExecutionLocationOverviewDocument;

useGetExecutionLocationOverviewQuery.getKey = (variables?: GetExecutionLocationOverviewQueryVariables) => variables === undefined ? ['GetExecutionLocationOverview'] : ['GetExecutionLocationOverview', variables];


useGetExecutionLocationOverviewQuery.fetcher = (client: GraphQLClient, variables?: GetExecutionLocationOverviewQueryVariables, headers?: RequestInit['headers']) => fetcher<GetExecutionLocationOverviewQuery, GetExecutionLocationOverviewQueryVariables>(client, GetExecutionLocationOverviewDocument, variables, headers);
