import { useState } from 'react'

import { Add as CreateIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import type { GridValueFormatter } from '@mui/x-data-grid-pro'
import { useShallow } from 'zustand/react/shallow'

import { Columns, Table, renderCompanyAvatar } from '@leaf/table'
import { noop, route } from '@leaf/utilities'

import { graphqlClient } from '@/api'
import { parseVolumePattern } from '@/features/contract/contract.mapper'
import { useGetContractsQuery } from '@/features/contract/contract-overview.api.generated'
import { ContractOverviewCreateDialog } from '@/features/contract/contract-overview-create-dialog'
import { ContractOverviewImportDialog } from '@/features/contract/contract-overview-import-dialog'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

const ContractOverview = () => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.contractOverview]),
  )

  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showImportDialog, setShowImportDialog] = useState(false)

  const { data, isLoading } = useGetContractsQuery(
    graphqlClient,
    {},
    {
      select: (response) =>
        response.contracts.map((contract) => {
          const contractRoute = contract.contractRoutes.find((cr) => cr.isPrimary)
          return {
            ...contract,
            accessorialIds: contract.contractAccessorials.map(
              (contractAccessorial) => contractAccessorial.accessorial.id,
            ),
            accessorials: contract.contractAccessorials.map(
              (contractAccessorial) => contractAccessorial.accessorial.name,
            ),
            account: contract.buyer ?? contract.seller,
            dailies: parseVolumePattern(contract.volumePattern),
            domiciles: contract.domiciles.map((d) => d.name),
            moveType: contract.contractRoutes.length
              ? contract.contractRoutes[0].route.tripType
              : null,
            planIds: contract.shipperRoutePlans.map((plan) => plan.shipperRoutePlanId),
            routeIds: contract.contractRoutes.map((cr) => cr.routeId),
            ...route.getOriginsDestinations(contractRoute ? [contractRoute] : []),
          }
        }),
    },
  )

  const toggleCreateDialog = () => setShowCreateDialog(!showCreateDialog)
  const toggleImportDialog = () => setShowImportDialog(!showImportDialog)

  useTitles([{ value: 'Contracts' }])
  useActions([
    <Button key='createContract' onClick={toggleCreateDialog} startIcon={<CreateIcon />}>
      Create
    </Button>,
  ])

  const importOptions = {
    onClick: toggleImportDialog,
  }

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
    },
    {
      field: 'routeIds',
      headerName: 'Route IDs',
      type: 'list',
    },
    {
      field: 'planIds',
      headerName: 'Plan IDs',
    },
    {
      field: 'packageId',
      headerName: 'Package ID',
    },
    {
      field: 'contractStatus',
      headerName: 'Status',
    },
    {
      field: 'contractType',
      headerName: 'Contract Type',
    },
    {
      field: 'moveType',
      headerName: 'Move Type',
    },
    {
      field: 'origins',
      headerName: 'Origins',
      type: 'list',
    },
    {
      field: 'destinations',
      headerName: 'Destinations',
      type: 'list',
    },
    { field: 'account.id', headerName: 'Account ID' },
    {
      field: 'account.name',
      headerName: 'Account',
      minWidth: 250,
      renderCell: renderCompanyAvatar({ id: 'account.id', name: 'account.name' }),
    },
    {
      field: 'account.companyType',
      headerName: 'Account Type',
      type: 'singleSelect',
      valueOptions: ['SHIPPER', 'CARRIER'],
    },
    {
      field: 'startDate',
      headerName: 'Start',
      type: 'date',
    },
    {
      field: 'endDate',
      headerName: 'End',
      type: 'date',
    },
    {
      field: 'equipmentType.name',
      headerName: 'Equipment',
    },
    {
      field: 'equipmentType.id',
      headerName: 'Equipment ID',
    },
    {
      field: 'fuelSurchargeSchedule.name',
      headerName: 'FSC',
    },
    {
      field: 'fuelSurchargeSchedule.id',
      headerName: 'FSC ID',
    },
    {
      field: 'accessorials',
      headerName: 'Accessorials',
      type: 'list',
    },
    {
      field: 'accessorialIds',
      headerName: 'Accessorial IDs',
      type: 'list',
    },
    {
      field: 'minVolume',
      headerName: 'Min Weekly Volume',
    },
    {
      field: 'maxVolume',
      headerName: 'Max Weekly Volume',
    },
    {
      field: 'dailies.SUNDAY',
      headerName: 'Sunday Volume',
    },
    {
      field: 'dailies.MONDAY',
      headerName: 'Monday Volume',
    },
    {
      field: 'dailies.TUESDAY',
      headerName: 'Tuesday Volume',
    },
    {
      field: 'dailies.WEDNESDAY',
      headerName: 'Wednesday Volume',
    },
    {
      field: 'dailies.THURSDAY',
      headerName: 'Thursday Volume',
    },
    {
      field: 'dailies.FRIDAY',
      headerName: 'Friday Volume',
    },
    {
      field: 'dailies.SATURDAY',
      headerName: 'Saturday Volume',
    },
    {
      field: 'volumeType',
      headerName: 'Volume Type',
    },
    {
      field: 'volumeFrequency',
      headerName: 'Volume Frequency',
    },
    {
      field: 'linehaulRpm',
      headerName: 'LH RPM',
      type: 'money',
    },
    {
      field: 'minimumLinehaulCharge',
      headerName: 'Min LH Charge',
      type: 'money',
    },
    {
      field: 'fixedFeePerShipment',
      headerName: 'Fixed Fee Per Shipment',
      type: 'money',
    },
    {
      field: 'pricingMechanism',
      headerName: 'Pricing Mechanism',
    },
    {
      field: 'pricingIndex',
      headerName: 'Pricing Index',
    },
    {
      field: 'pricingIndexPremium',
      headerName: 'Pricing Index Premium',
    },
    {
      field: 'pricingIndexPremiumOverrideSun',
      headerName: 'Pricing Index Premium Override Sunday',
    },
    {
      field: 'minChargeableMiles',
      headerName: 'Min Chargeable Miles',
    },
    {
      field: 'minChargeableMilesFrequency',
      headerName: 'Min Chargeable Miles Frequency',
    },
    {
      field: 'carrierUpcharge',
      headerName: 'Carrier Upcharge',
      type: 'number',
      valueFormatter: (params: GridValueFormatter) => {
        if (params === undefined || params === null) {
          return null
        }
        const n = Number(params)
        return `${n}%`
      },
    },
    {
      field: 'assetPricing',
      headerName: 'Asset Pricing',
      type: 'money',
    },
    {
      field: 'assetPricingFrequency',
      headerName: 'Asset Pricing Frequency',
    },
    {
      field: 'minTenderLeadTimeHrs',
      headerName: 'Min Tender Lead Time (hrs)',
    },
    {
      field: 'includeWeekends',
      headerName: 'Include Weekends',
      type: 'boolean',
    },
    {
      field: 'isBackupAward',
      headerName: 'Backup Award',
      type: 'boolean',
    },
    {
      field: 'useBtf',
      headerName: 'BTF',
      type: 'boolean',
    },
    {
      field: 'isFleet',
      headerName: 'Fleet',
      type: 'boolean',
    },
    {
      field: 'domiciles',
      headerName: 'Domiciles',
      type: 'list',
    },
    {
      field: 'notes',
      headerName: 'Notes',
    },
    {
      field: 'createdByUser.email',
      headerName: 'Created By',
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
    },
    {
      field: 'updatedByUser.email',
      headerName: 'Updated By',
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
    },
  ]

  const exportOptions = { fileName: 'contracts' }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        'accessorials': false,
        'account.id': false,
        'assetPricing': false,
        'assetPricingFrequency': false,
        'carrierUpcharge': false,
        'contractType': false,
        'dailies.FRIDAY': false,
        'dailies.MONDAY': false,
        'dailies.SATURDAY': false,
        'dailies.SUNDAY': false,
        'dailies.THURSDAY': false,
        'dailies.TUESDAY': false,
        'dailies.WEDNESDAY': false,
        'domiciles': false,
        'equipmentType.id': false,
        'equipmentType.name': false,
        'fixedFeePerShipment': false,
        'fuelSurchargeSchedule.id': false,
        'fuelSurchargeSchedule.name': false,
        'id': false,
        'includeWeekends': false,
        'isBackupAward': false,
        'isFleet': false,
        'minChargeableMiles': false,
        'minChargeableMilesFrequency': false,
        'minTenderLeadTimeHrs': false,
        'minVolume': false,
        'minimumLinehaulCharge': false,
        'moveType': false,
        'notes': false,
        'packageId': false,
        'planIds': false,
        'pricingIndex': false,
        'pricingIndexPremium': false,
        'pricingIndexPremiumOverrideSun': false,
        'pricingMechanism': false,
        'routeIds': false,
        'useBtf': false,
        'volumeFrequency': false,
        'volumeType': false,
      },
    },
  }

  return (
    <>
      <Table
        // @ts-expect-error
        columns={columns}
        exportOptions={exportOptions}
        importOptions={importOptions}
        initialState={initialState}
        loading={isLoading}
        name='contractOverview'
        persist={{
          fn: changeTable,
          save: savedState,
        }}
        rows={data}
      />

      {showCreateDialog && (
        <ContractOverviewCreateDialog
          onClose={toggleCreateDialog}
          onSuccess={noop}
          open={showCreateDialog}
        />
      )}

      {showImportDialog && (
        <ContractOverviewImportDialog
          onClose={toggleImportDialog}
          onSuccess={noop}
          open={showImportDialog}
        />
      )}
    </>
  )
}

export { ContractOverview }
