import { Login as DetailsIcon } from '@mui/icons-material'
import type { GridRowParams } from '@mui/x-data-grid-pro'
import { useShallow } from 'zustand/react/shallow'

import { AsyncTable, renderCompanyAvatar } from '@leaf/table'
import { ActionLink } from '@leaf/table/columns'

import { graphqlClient } from '@/api'
import { getLocation } from '@/features/contract'
import { type GetShipmentsQuery, useGetShipmentsQuery } from '@/features/shipment/shipment-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const columns = [
  {
    field: 'actions',
    getActions: (params: GridRowParams) => [
      <ActionLink className='e2e-detail-action' icon={<DetailsIcon />} label='Details' to={params.id.toString()} />,
    ],
    headerName: 'Actions',
    minWidth: 80,
    type: 'actions',
  },
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'carrierTripTender.tripId',
    headerName: 'Load',
  },
  {
    field: 'company.name',
    headerName: 'Shipper',
    minWidth: 200,
    renderCell: renderCompanyAvatar({
      id: 'company.id',
      name: 'company.name',
    }),
  },
  {
    field: 'shipperShipmentId',
    headerName: 'Reference',
  },
  {
    field: 'transportationMode',
    headerName: 'Mode',
  },
  {
    field: 'equipmentType.name',
    headerName: 'Equipment',
  },
  {
    field: 'shipmentExecutionStatus',
    headerName: 'Status',
  },
  {
    field: 'expectedShipmentDate',
    headerName: 'Shipment Date',
    type: 'date',
  },
  {
    field: 'origin',
    headerName: 'Origin',
    minWidth: 400,
    type: 'list',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    minWidth: 400,
    type: 'list',
  },
  {
    field: 'legNumber',
    headerName: 'Leg',
    type: 'number',
  },
  {
    field: 'carrierTripTender.carrier.name',
    headerName: 'Carrier',
    minWidth: 200,
    renderCell: renderCompanyAvatar({
      id: 'carrierTripTender.carrier.id',
      name: 'carrierTripTender.carrier.name',
    }),
  },
]

const initialState = {
  columns: {
    columnVisibilityModel: {
      id: false,
      legNumber: false,
    },
  },
}

// TODO: LEAF-5120 Implement export for server-side tables
const ShipmentOverview = () => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.shipmentOverview]),
  )

  useTitles([{ value: 'Shipments' }])

  return (
    <AsyncTable
      // @ts-expect-error
      columns={columns}
      initialState={initialState}
      name='shipmentOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      query={{
        client: graphqlClient,
        fn: useGetShipmentsQuery,
        select: (response: GetShipmentsQuery) => ({
          count: response?.shipmentTendersAggregate.aggregate?.count,
          rows: response?.shipmentTenders.map(({ destination, origin, ...rest }) => ({
            ...rest,
            destination: getLocation(destination),
            origin: getLocation(origin),
          })),
        }),
      }}
    />
  )
}

export { ShipmentOverview }
