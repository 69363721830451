import { type ChangeEvent, type SyntheticEvent, useEffect, useState } from 'react'

import { Delete as DeleteIcon, ArrowRightAlt as ToIcon } from '@mui/icons-material'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'

import { type JavalinErrorType, javalinErrorToString } from '@leaf/api'
import { CompanyAvatar, Map, Route, Spinner, SubCard, Text } from '@leaf/components'
import type * as Types from '@leaf/types'
import { color, time } from '@leaf/utilities'

import { graphqlClient, restClient } from '@/api'
import type { ContractRouteType } from '@/features/contract/contract-details.api'
import {
  type GetShipperLanesQuery,
  useGetShipperLanesQuery,
  useSearchLanesQuery,
  useSearchShipperLaneQuery,
} from '@/features/contract/contract-details.api.generated'
import { useStore } from '@/store'

type LaneType = Types.NoUndefineds<GetShipperLanesQuery['routingLanes'][0]>

type ContractDetailsRouteDialogProps = {
  companyId: any
  contractId: any
  contractRoute?: ContractRouteType | null
  isShipper: boolean
  onSuccess: (contractRoute: ContractRouteType, originalRouteId?: string) => void
} & Types.Dialog
const ContractDetailsRouteDialog = ({
  companyId,
  contractId,
  contractRoute,
  isShipper,
  onClose,
  onSuccess,
  open,
}: ContractDetailsRouteDialogProps) => {
  const addSnackbar = useStore((state) => state.addSnackbar)

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<null | string[]>(null)
  const [laneSearch, setLaneSearch] = useState('')
  const [moveType, setMoveType] = useState('ONE_WAY')
  const [isPrimary, setIsPrimary] = useState(false)
  const [legs, setLegs] = useState<LaneType[]>([])

  useEffect(() => {
    if (contractRoute) {
      setMoveType(contractRoute.route.tripType)
      setIsPrimary(!!contractRoute.isPrimary)
      setLegs(contractRoute.route.legs.map((leg) => leg.lane as LaneType))
    }
  }, [contractRoute])

  const { data: shipperLanes } = useGetShipperLanesQuery(
    graphqlClient,
    { shipperId: companyId },
    {
      enabled: isShipper && !laneSearch,
      select: (response) => response.routingLanes,
    },
  )

  const { data: shipperLanesSearchResult } = useSearchShipperLaneQuery(
    graphqlClient,
    { search: laneSearch, shipperId: companyId },
    {
      enabled: isShipper && !!laneSearch,
      select: (response) => response.routingLanes,
    },
  )

  const { data: carrierLanes } = useSearchLanesQuery(
    graphqlClient,
    { search: laneSearch },
    {
      enabled: !isShipper && !!laneSearch,
      select: (response) => response.routingLanes,
    },
  )

  let laneSearchResults = []
  if (isShipper) {
    if (laneSearch) {
      laneSearchResults = shipperLanesSearchResult || []
    } else {
      laneSearchResults = shipperLanes || []
    }
  } else {
    laneSearchResults = carrierLanes || []
  }

  const onChangeMoveType = (event: SelectChangeEvent<string>) => {
    setMoveType(event.target.value)
  }
  const onChangeIsPrimary = () => {
    setIsPrimary(!isPrimary)
  }

  const filterOptions = (x: any) => {
    return x
  }
  const getOptionLabel = (value: LaneType) => {
    return `${value.originName} → ${value.destinationName}`
  }

  const onLaneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value?.length > 2) {
      setLaneSearch(`%${value}%`)
    }
  }

  const onLaneSelect = (
    _: SyntheticEvent<Element, Event>,
    value: LaneType | null,
    reason: string,
  ) => {
    if (reason === 'selectOption' && value) {
      setLegs([...legs, value])
    }
  }

  const removeLeg = (i: number) => () => {
    setLegs(legs.filter((_, index) => index !== i))
  }

  const handleSave = async () => {
    setIsLoading(true)

    try {
      const legIds = legs.map((leg) => leg.id)

      // In case of an edit, delete the original route
      if (contractRoute) {
        await restClient.delete(
          `contracting/contracts/${contractId}/routes/${contractRoute.routeId}`,
        )
      }

      // In the case of a single leg, a route equal to the lane ID was created
      let routeId = legIds[0]
      if (legIds.length > 1) {
        const routesResponse = await restClient.post('routing/routes', {
          lanes: legIds,
          tripType: moveType,
        })
        routeId = routesResponse.data.id
      }
      await restClient.post(`contracting/contracts/${contractId}/routes/${routeId}`, {
        isPrimary,
      })
      addSnackbar({
        message: `Route successfully saved`,
        severity: 'success',
      })
      onSuccess(
        {
          isPrimary,
          route: {
            id: routeId,
            legs: legs.map((leg) => ({ lane: leg })),
            tripType: moveType,
          },
          routeId,
        },
        contractRoute?.routeId,
      )
      onClose()
    } catch (error) {
      const javalinErrors = javalinErrorToString(error as JavalinErrorType)
      setErrors(javalinErrors)
    } finally {
      setIsLoading(false)
    }
  }

  const from = legs[0]?.originName ?? 'Origin'
  const to = legs.length ? legs[legs.length - 1]?.destinationName : 'Destination'

  return (
    <Dialog fullWidth maxWidth='lg' onClose={onClose} open={open}>
      {isLoading ? <Spinner overlay /> : null}

      <DialogContent>
        <Grid container item spacing={2} xs={12}>
          <SubCard
            actions={[
              <Box>
                <FormControlLabel
                  control={<Checkbox checked={isPrimary} onChange={onChangeIsPrimary} />}
                  label='PRIMARY'
                  sx={{ letterSpacing: '0.01em' }}
                />
              </Box>,
            ]}
            // @ts-expect-error
            title={
              <Box sx={{ display: 'flex' }}>
                <Text.H6>{from}</Text.H6>

                <ToIcon sx={{ marginLeft: '1em', marginRight: '1em' }} />

                <Text.H6>{to}</Text.H6>
              </Box>
            }
            xs={6}
          >
            <Select
              fullWidth
              id='moveType'
              label='Move Type'
              onChange={onChangeMoveType}
              required
              value={moveType}
              variant='standard'
            >
              {[
                { id: 'ONE_WAY', label: 'ONE_WAY' },
                { id: 'ROUND_TRIP', label: 'ROUND_TRIP' },
                { id: 'CIRCUIT', label: 'CIRCUIT' },
                { id: 'CONTINUOUS_MOVE', label: 'CONTINUOUS_MOVE' },
                { id: 'SHORTY', label: 'SHORTY' },
              ].map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>

            {!legs.length && (
              <Alert severity='info' sx={{ marginTop: '2em' }}>
                Search and add a lane
              </Alert>
            )}

            {legs.map((leg, i) => (
              <Box
                key={leg.originName}
                sx={{
                  display: 'flex',
                  marginBottom: '48px',
                  position: 'relative',
                }}
              >
                <Route.RouteStyled
                  $color={color.lane(i)}
                  $position={i * 2 + 1}
                  sx={{ marginLeft: '1.5em' }}
                />

                <Route.LaneStyled>
                  <Typography variant='body2'>{leg.originName}</Typography>

                  <Box>
                    <Route.DistanceStyled>
                      <Box gap={8} sx={{ display: 'flex' }}>
                        {leg.leafMiles ? (
                          <Typography variant='caption'>{leg.leafMiles} miles</Typography>
                        ) : (
                          <Typography variant='caption'>Unknown miles</Typography>
                        )}
                      </Box>

                      <Route.HorizontalLineStyled />

                      <Box sx={{ marginLeft: '2', marginRight: '2' }}>
                        <CompanyAvatar id={leg.shipper.id} />
                      </Box>

                      <IconButton onClick={removeLeg(i)}>
                        <DeleteIcon />
                      </IconButton>
                    </Route.DistanceStyled>
                  </Box>

                  <Typography variant='body2'>{leg.destinationName}</Typography>
                </Route.LaneStyled>
              </Box>
            ))}
          </SubCard>

          <Grid container item spacing={2} xs={6}>
            <SubCard>
              <Autocomplete
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                onChange={onLaneSelect}
                options={laneSearchResults as LaneType[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search lanes'
                    onChange={onLaneChange}
                    required
                    variant='standard'
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <Box component='li' {...props} sx={{ borderBottom: '1px solid #cccccc' }}>
                      <Grid
                        container
                        sx={{
                          alignItems: 'center',
                          direction: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item xs={4}>
                          <CompanyAvatar chip id={option.shipper.id} name={option.shipper.name} />
                        </Grid>

                        <Grid item xs={4}>
                          <Text.H6>{option.originName}</Text.H6>
                        </Grid>

                        <Grid item xs={4}>
                          <Text.H6>{option.destinationName}</Text.H6>
                        </Grid>

                        <Grid item xs={4} />

                        <Grid item xs={4}>
                          <Typography>{option.leafMiles} miles</Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography>{time.toLocalDatetime(option.createdAt)}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                }}
              />
            </SubCard>

            <SubCard sx={{ padding: '0 !important' }}>
              <Grid item sx={{ height: '600px' }} xs={12}>
                <Map mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY} zoom={2.5}>
                  {legs.map((leg, i) => (
                    <Route.MapLane
                      color={color.lane(i)}
                      geometry={leg.path}
                      id={i.toString()}
                      key={i}
                      position={i * 2}
                    />
                  ))}
                </Map>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </DialogContent>

      {errors && <Alert severity='error'>Could not save route: {errors}</Alert>}

      <DialogActions>
        <Button color='secondary' onClick={onClose} variant='contained'>
          Cancel
        </Button>

        <Button color='primary' onClick={handleSave} variant='contained'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ContractDetailsRouteDialog }
