import { useShallow } from 'zustand/react/shallow'

import { Columns, Table, renderCompanyAvatar } from '@leaf/table'

import { graphqlClient } from '@/api'
import { mapExecutionLocation } from '@/features/execution-location/execution-location.mapper'
import { useGetExecutionLocationOverviewQuery } from '@/features/execution-location/execution-location-overview.api.generated'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

const exportOptions = { fileName: 'execution_locations' }

const ExecutionLocationOverview = () => {
  const [savedState, changeTable] = useStore(
    useShallow((state) => [state.executionLocationOverview, state.changeTable]),
  )

  useTitles([{ value: 'Execution Locations' }])

  const { data, isLoading } = useGetExecutionLocationOverviewQuery(
    graphqlClient,
    {},
    {
      select: ({ executionLocation }) => executionLocation.map(mapExecutionLocation),
    },
  )

  const columns = [
    Columns.Action(),
    { field: 'id', headerName: 'ID' },
    {
      field: 'company.name',
      headerName: 'Shipper',
      renderCell: renderCompanyAvatar({
        id: 'company.id',
        name: 'company.name',
      }),
    },
    { field: 'shipperLocationName', headerName: 'Name' },
    { field: 'shipperLocationReference', headerName: 'Reference' },
    { field: 'address', headerName: 'Address', type: 'list' },
    { field: 'country', headerName: 'Country' },
    { field: 'timezone', headerName: 'Timezone' },
    { field: 'createdAt', headerName: 'Created At', type: 'date' },
    { field: 'lastUpdatedAt', headerName: 'Updated At', type: 'date' },
  ]

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      exportOptions={exportOptions}
      loading={isLoading}
      name='executionLocationOverview'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
    />
  )
}

export { ExecutionLocationOverview }
