// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetAdaptConstellationQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetAdaptConstellationQuery = { __typename?: 'query_root', constellationsByPk?: { __typename?: 'constellations', id: any, name: string, shipperWhitelist: Array<any>, constellationLanes: Array<{ __typename?: 'constellationLanes', equipmentClass: string, batchDate: any, annualizedLoads?: number, lane: { __typename?: 'analyticsLanes', id: any, companyId: any, leafMiles?: any, geometry: any, company: { __typename?: 'companies', name: string }, originLocation?: { __typename?: 'analyticsLocations', name: string }, destinationLocation?: { __typename?: 'analyticsLocations', name: string } } }> } };

export type GetAdaptConstellationNetworkMovesQueryVariables = Types.Exact<{
  constellationId: Types.Scalars['uuid']['input'];
}>;


export type GetAdaptConstellationNetworkMovesQuery = { __typename?: 'query_root', sortedConstellationNetworkMoves: Array<{ __typename?: 'sortedConstellationNetworkMove', constellation_id: any, network_move_id: any, move_type?: string, l0_id: any, l1_id: any, l2_id?: any, l3_id?: any, l4_id?: any, l0_shipper_id: any, l1_shipper_id: any, l2_shipper_id: any, l3_shipper_id?: any, l4_shipper_id?: any, l0_shipper_name: string, l1_shipper_name: string, l2_shipper_name?: string, l3_shipper_name?: string, l4_shipper_name?: string, l0_loads?: number, l1_loads?: number, l2_loads?: number, l3_loads?: any, l4_loads?: any, l0_miles?: any, l1_miles?: any, l2_miles?: any, l3_miles?: any, l4_miles?: any, l0_geometry: any, l1_geometry: any, l2_geometry: any, l3_geometry?: any, l4_geometry?: any, l0_origin: string, l1_origin: string, l2_origin: string, l3_origin?: string, l4_origin?: string, l0_destination: string, l1_destination: string, l2_destination: string, l3_destination?: string, l4_destination?: string }> };

export type UpdateShipperWhitelistMutationVariables = Types.Exact<{
  constellationId: Types.Scalars['uuid']['input'];
  shipperWhitelist?: Types.InputMaybe<Array<Types.Scalars['bigint']['input']> | Types.Scalars['bigint']['input']>;
}>;


export type UpdateShipperWhitelistMutation = { __typename?: 'mutation_root', updateConstellationsByPk?: { __typename?: 'constellations', id: any } };



export const GetAdaptConstellationDocument = `
    query GetAdaptConstellation($id: uuid!) {
  constellationsByPk(id: $id) {
    id
    name
    shipperWhitelist
    constellationLanes {
      equipmentClass
      batchDate
      annualizedLoads
      lane {
        id
        companyId
        company {
          name
        }
        leafMiles
        originLocation {
          name
        }
        destinationLocation {
          name
        }
        geometry
      }
    }
  }
}
    `;

export const useGetAdaptConstellationQuery = <
      TData = GetAdaptConstellationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAdaptConstellationQueryVariables,
      options?: UseQueryOptions<GetAdaptConstellationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAdaptConstellationQuery, TError, TData>(
      ['GetAdaptConstellation', variables],
      fetcher<GetAdaptConstellationQuery, GetAdaptConstellationQueryVariables>(client, GetAdaptConstellationDocument, variables, headers),
      options
    )};

useGetAdaptConstellationQuery.document = GetAdaptConstellationDocument;

useGetAdaptConstellationQuery.getKey = (variables: GetAdaptConstellationQueryVariables) => ['GetAdaptConstellation', variables];


useGetAdaptConstellationQuery.fetcher = (client: GraphQLClient, variables: GetAdaptConstellationQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAdaptConstellationQuery, GetAdaptConstellationQueryVariables>(client, GetAdaptConstellationDocument, variables, headers);

export const GetAdaptConstellationNetworkMovesDocument = `
    query GetAdaptConstellationNetworkMoves($constellationId: uuid!) {
  sortedConstellationNetworkMoves(args: {constellationId: $constellationId}) {
    constellation_id
    network_move_id
    move_type
    l0_id
    l1_id
    l2_id
    l3_id
    l4_id
    l0_shipper_id
    l1_shipper_id
    l2_shipper_id
    l3_shipper_id
    l4_shipper_id
    l0_shipper_name
    l1_shipper_name
    l2_shipper_name
    l3_shipper_name
    l4_shipper_name
    l0_loads
    l1_loads
    l2_loads
    l3_loads
    l4_loads
    l0_miles
    l1_miles
    l2_miles
    l3_miles
    l4_miles
    l0_geometry
    l1_geometry
    l2_geometry
    l3_geometry
    l4_geometry
    l0_origin
    l1_origin
    l2_origin
    l3_origin
    l4_origin
    l0_destination
    l1_destination
    l2_destination
    l3_destination
    l4_destination
  }
}
    `;

export const useGetAdaptConstellationNetworkMovesQuery = <
      TData = GetAdaptConstellationNetworkMovesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAdaptConstellationNetworkMovesQueryVariables,
      options?: UseQueryOptions<GetAdaptConstellationNetworkMovesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAdaptConstellationNetworkMovesQuery, TError, TData>(
      ['GetAdaptConstellationNetworkMoves', variables],
      fetcher<GetAdaptConstellationNetworkMovesQuery, GetAdaptConstellationNetworkMovesQueryVariables>(client, GetAdaptConstellationNetworkMovesDocument, variables, headers),
      options
    )};

useGetAdaptConstellationNetworkMovesQuery.document = GetAdaptConstellationNetworkMovesDocument;

useGetAdaptConstellationNetworkMovesQuery.getKey = (variables: GetAdaptConstellationNetworkMovesQueryVariables) => ['GetAdaptConstellationNetworkMoves', variables];


useGetAdaptConstellationNetworkMovesQuery.fetcher = (client: GraphQLClient, variables: GetAdaptConstellationNetworkMovesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAdaptConstellationNetworkMovesQuery, GetAdaptConstellationNetworkMovesQueryVariables>(client, GetAdaptConstellationNetworkMovesDocument, variables, headers);

export const UpdateShipperWhitelistDocument = `
    mutation UpdateShipperWhitelist($constellationId: uuid!, $shipperWhitelist: [bigint!]) {
  updateConstellationsByPk(
    pkColumns: {id: $constellationId}
    _set: {shipperWhitelist: $shipperWhitelist}
  ) {
    id
  }
}
    `;

export const useUpdateShipperWhitelistMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateShipperWhitelistMutation, TError, UpdateShipperWhitelistMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateShipperWhitelistMutation, TError, UpdateShipperWhitelistMutationVariables, TContext>(
      ['UpdateShipperWhitelist'],
      (variables?: UpdateShipperWhitelistMutationVariables) => fetcher<UpdateShipperWhitelistMutation, UpdateShipperWhitelistMutationVariables>(client, UpdateShipperWhitelistDocument, variables, headers)(),
      options
    )};

useUpdateShipperWhitelistMutation.getKey = () => ['UpdateShipperWhitelist'];


useUpdateShipperWhitelistMutation.fetcher = (client: GraphQLClient, variables: UpdateShipperWhitelistMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateShipperWhitelistMutation, UpdateShipperWhitelistMutationVariables>(client, UpdateShipperWhitelistDocument, variables, headers);
