import { clean } from '@leaf/form'
import type * as Types from '@leaf/types'

import { graphqlClient } from '@/api'
import { GetExecutionLocationDetailsDocument, type GetExecutionLocationDetailsQuery } from '@/features/execution-location/execution-location-details.api.generated'

type ExecutionLocationDetailsQueryReturnType = Types.NoUndefined<
  GetExecutionLocationDetailsQuery['executionLocationByPk']
>

const executionLocationDetailsQuery = (id: string) => ({
  queryFn: async () => {
    const executionLocationResponse = await graphqlClient.request(GetExecutionLocationDetailsDocument, {
      id,
    })
    const data = clean(executionLocationResponse) as GetExecutionLocationDetailsQuery
    const executionLocation = (data.executionLocationByPk || {}) as ExecutionLocationDetailsQueryReturnType
    return executionLocation
  },
  queryKey: [`execution-location-details-${id}`],
})

export { executionLocationDetailsQuery }
export type { ExecutionLocationDetailsQueryReturnType }
