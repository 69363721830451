import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import {
  Add as CreateIcon,
  Delete as DeleteIcon,
  Star as PrimaryIcon,
  Edit as RouteEditIcon,
  ArrowRightAlt as ToIcon,
} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { isBefore } from 'date-fns'
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
  TextareaAutosizeElement,
  useForm,
  useWatch,
} from 'react-hook-form-mui'
import { DatePickerElement } from 'react-hook-form-mui/date-pickers'
import * as z from 'zod'

import { javalinErrorToString } from '@leaf/api'
import {
  CompanyAvatar,
  CompanyAvatarLink,
  DataRowSection,
  Map,
  Page,
  Progress,
  Route,
  type SnackbarType,
  SubCard,
  SubMenu,
  Tabs,
  Text,
} from '@leaf/components'
import {
  LocationAutocomplete,
  clean,
  createRequire,
  select,
  useLocations,
  validation as v,
} from '@leaf/form'
import { usePrevious, useTabs, useUpdateEffect } from '@leaf/hooks'
import type * as Types from '@leaf/types'
import { color, route, time } from '@leaf/utilities'

import { graphqlClient, restClient } from '@/api'
import { useGetAccessorialsQuery } from '@/api/accessorials.api.generated'
import { useGetCompaniesQuery } from '@/api/companies.api.generated'
import { useGetEquipmentTypesQuery } from '@/api/equipment-types.api.generated'
import { useGetFuelSurchargeSchedulesQuery } from '@/api/fuel-surcharge-schedules.api.generated'
import { CONTAINER_TABLE_HEIGHT } from '@/constants'
import {
  type ContractRouteType,
  contractDetailsQuery,
} from '@/features/contract/contract-details.api'
import type { ShipmentType } from '@/features/contract/contract-details.types'
import { ContractDetailsDeleteDialog } from '@/features/contract/contract-details-delete-dialog'
import { ContractDetailsMatches } from '@/features/contract/contract-details-matches'
import { useGetContractMatchesQuery } from '@/features/contract/contract-details-matches.api.generated'
import { ContractDetailsRouteDialog } from '@/features/contract/contract-details-route-dialog'
import { ContractDetailsShipments } from '@/features/contract/contract-details-shipments'
import {
  type GetContractShipmentsQuery,
  useGetContractShipmentsQuery,
} from '@/features/contract/contract-details-shipments.api.generated'
import { useActions, useTitles } from '@/hooks'
import { useStore } from '@/store'

type LocationType =
  | GetContractShipmentsQuery['shipmentTenders'][0]['destination']
  | GetContractShipmentsQuery['shipmentTenders'][0]['origin']

const getLocation = (location?: LocationType) => {
  if (!location) {
    return []
  }
  return [
    location.shipperLocationName ?? location.shipperLocationName ?? location.businessName ?? '-',
    location.street ?? '-',
    `${location.city}, ${location.state} ${location.zip}`,
  ]
}

const isInvalidDailyFrequency = (df?: null | number | string) => {
  if (df === null) {
    return true
  }
  if (df === undefined) {
    return true
  }
  if (df === '') {
    return true
  }
  if (Number(df) < 0) {
    return true
  }
  return false
}

type CompaniesAggregationType = {
  carriers: { id: string; label: string }[]
  shippers: { id: string; label: string }[]
}

/* eslint-disable perfectionist/sort-objects */
const schema = z
  .object({
    company: v._select,
    type: v._string,
    status: v._string,
    planId: v.__string,
    notes: v.__string,
    equipmentType: v._select,
    accessorials: v.__selects,
    useBtf: v.__boolean,

    // CARRIER
    fleet: v.__boolean,
    domicileAddress: z.record(z.any(), z.any()).nullish(),
    domicileName: v.__string,

    backupAward: v.__boolean,
    volumeType: v._string,
    volumeFrequency: v._string,
    startDate: v.__date,
    endDate: v.__date,

    // WEEKLY
    minVolume: v.__number,
    maxVolume: v.__number,

    // DAILY
    includeWeekends: v.__boolean,
    su: v.__number,
    sa: v.__number,
    mo: v.__number,
    tu: v.__number,
    th: v.__number,
    we: v.__number,
    fr: v.__number,

    pricingMechanism: v._string,

    // FIXED
    fixedFeePerShipment: v.__number,
    linehaulRpm: v.__number,
    minLinehaulCharge: v.__number,

    // INDEX
    pricingIndex: v.__string,
    allIn: v.__boolean,
    pricingIndexPremium: v.__number,
    pricingIndexPremiumOverrideSunday: v.__number,
    minTenderLeadTime: v.__number,

    // OPEN
    carrierUpcharge: v.__number,

    // CARRIER AND NOT INDEX
    assetPricing: v.__number,
    assetPricingFrequency: v.__string,
    minChargeableMiles: v.__number,
    minChargeableMilesFrequency: v.__string,

    // NOT INDEX AND NOT ALL_IN
    fuelSurchargeSchedule: v.__select,

    moveType: v.__string,
  })
  .superRefine((values, ctx) => {
    const customRequire = createRequire(ctx)

    customRequire(values.fleet && !values.domicileName, 'domicileName')
    customRequire(values.fleet && !values.domicileAddress, 'domicileAddress')

    customRequire(values.volumeFrequency === 'WEEKLY' && !values.maxVolume, 'maxVolume')

    if (values.volumeFrequency === 'DAILY') {
      customRequire(isInvalidDailyFrequency(values.su), 'su')
      customRequire(isInvalidDailyFrequency(values.mo), 'mo')
      customRequire(isInvalidDailyFrequency(values.tu), 'tu')
      customRequire(isInvalidDailyFrequency(values.we), 'we')
      customRequire(isInvalidDailyFrequency(values.th), 'th')
      customRequire(isInvalidDailyFrequency(values.fr), 'fr')
      customRequire(isInvalidDailyFrequency(values.sa), 'sa')
    }

    customRequire(!values.allIn && !values.fuelSurchargeSchedule, 'fuelSurchargeSchedule')

    customRequire(
      values.minChargeableMiles && !values.minChargeableMilesFrequency,
      'minChargeableMilesFrequency',
    )

    if (values.startDate && values.endDate && isBefore(values.endDate, values.startDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'End date must be after start date',
        path: ['endDate'],
      })
    }
  })
type Schema = z.infer<typeof schema>

const toPayload = (values: Schema) => {
  const payload: Types.KeyValue = {
    contractStatus: values.status,
    equipmentTypeId: values.equipmentType.id,
    startDate: time.toDate(values.startDate, 'yyyy-MM-dd'),
    endDate: time.toDate(values.endDate, 'yyyy-MM-dd'),
    shipperRoutePlanId: values.planId,
    notes: values.notes,
    accessorialIds: values.accessorials?.map((a) => a.id),
    isBackupAward: !!values.backupAward,
    volumeType: values.volumeType,
    volumeFrequency: values.volumeFrequency,
    pricingMechanism: values.pricingMechanism,
  }

  if (values.type === 'SHIPPER') {
    payload.buyerId = values.company.id
  }

  if (values.type === 'CARRIER') {
    payload.sellerId = values.company.id
    payload.useBtf = !!values.useBtf
  }

  if (values.volumeFrequency === 'DAILY') {
    const dailyPattern = {
      SUNDAY: values.su,
      MONDAY: values.mo,
      TUESDAY: values.tu,
      WEDNESDAY: values.we,
      THURSDAY: values.th,
      FRIDAY: values.fr,
      SATURDAY: values.sa,
    }
    const volumePattern = Object.entries(dailyPattern).reduce((acc, [key, value]) => {
      if (value) {
        acc.push(`${key}=${value}`)
        return acc
      }
      return acc
    }, [] as string[])
    payload.volumePattern = volumePattern.join(',')
  }

  if (values.volumeFrequency === 'WEEKLY') {
    payload.minVolume = values.minVolume
    payload.maxVolume = values.maxVolume
    payload.includeWeekends = !!values.includeWeekends
  }

  if (values.pricingMechanism === 'FIXED') {
    payload.fixedFeePerShipment = values.fixedFeePerShipment
    payload.linehaulRpm = values.linehaulRpm
    payload.minimumLinehaulCharge = values.minLinehaulCharge
  }

  if (values.pricingMechanism === 'INDEX') {
    payload.pricingIndex = values.pricingIndex
    payload.pricingIndexPremium = values.pricingIndexPremium
    payload.pricingIndexPremiumOverrideSun = values.pricingIndexPremiumOverrideSunday
    payload.minTenderLeadTimeHrs = values.minTenderLeadTime
  }

  if (values.pricingMechanism === 'OPEN_BOOK') {
    payload.carrierUpcharge = values.carrierUpcharge
  }

  if (!(values.pricingMechanism === 'INDEX' && values.allIn)) {
    payload.fscId = values.fuelSurchargeSchedule!.id
  }

  if (values.type === 'CARRIER') {
    payload.isFleet = !!values.fleet

    if (values.fleet) {
      payload.domicile = {
        name: values.domicileName,
        city: values.domicileAddress!.city,
        state: values.domicileAddress!.state,
        zip: values.domicileAddress!.zipCode,
        street: values.domicileAddress!.address,
        geo: JSON.stringify({
          type: 'Point',
          coordinates: [values.domicileAddress!.latitude, values.domicileAddress!.longitude],
        }),
      }
    }

    if (values.pricingMechanism !== 'INDEX') {
      payload.assetPricing = values.assetPricing
      payload.assetPricingFrequency = values.assetPricingFrequency
      payload.minChargeableMiles = values.minChargeableMiles
      payload.minChargeableMilesFrequency = values.minChargeableMilesFrequency
    }
  }
  return clean(payload)
}

const createJavalinErrorHandler =
  (addSnackbar: (snackbar: SnackbarType) => void) => (error: any) => {
    const messages = javalinErrorToString(error)
    messages.forEach((message) => addSnackbar({ message, severity: 'error' }))
  }

const ContractDetails = () => {
  const { contractId: id } = useParams()

  const addSnackbar = useStore((state) => state.addSnackbar)

  const { activeTab, setActiveTab } = useTabs()

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openRouteDialog, setOpenRouteDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedRoute, setSelectedRoute] = useState<ContractRouteType | null>(null)

  const { data } = useQuery(contractDetailsQuery(id!))
  const contract = data!

  const [contractRoutes, setContractRoutes] = useState<ContractRouteType[]>(contract.contractRoutes)

  const { data: companiesData } = useGetCompaniesQuery(
    graphqlClient,
    {},
    { select: (response) => response.companies },
  )

  const { data: equipmentTypes } = useGetEquipmentTypesQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.equipmentTypes) },
  )

  const { data: accessorials } = useGetAccessorialsQuery(
    graphqlClient,
    {},
    { select: (response) => select.fromResponses(response.accessorials) },
  )

  const { data: fuelSurchargeSchedulesData, isLoading: areFscsLoading } =
    useGetFuelSurchargeSchedulesQuery(
      graphqlClient,
      {},
      { select: (response) => response.fuelSurchargeSchedules },
    )

  const {
    loading: domicileAddressesLoading,
    locations: domicileAddresses,
    onLocationChange: onDomicileAddressChange,
  } = useLocations(restClient, () =>
    addSnackbar({ message: 'Failed to find location', severity: 'error' }),
  )

  const { data: matches, isLoading: isMatchesLoading } = useGetContractMatchesQuery(
    graphqlClient,
    { id },
    {
      select: ({ routingGuides }) =>
        routingGuides.map((routingGuide) => ({
          ...routingGuide,
          ...route.getOriginsDestinations(routingGuide.sellContract.contractRoutes),
        })),
      staleTime: 1800000,
    },
  )

  const { data: shipments, isLoading: isShipmentsLoading } = useGetContractShipmentsQuery(
    graphqlClient,
    { id },
    {
      select: ({ shipmentTenders }) =>
        shipmentTenders.map(({ destination, origin, ...rest }) => ({
          ...rest,
          destination: getLocation(destination),
          origin: getLocation(origin),
          pickupDate:
            rest.originAppointmentTime ??
            rest.originInitialAppointmentTime ??
            rest.expectedShipmentDate,
        })) as ShipmentType[],
      staleTime: 1800000,
    },
  )

  const companies = companiesData?.reduce(
    (acc, company) => {
      const c = { id: company.id, label: company.name }
      if (company.companyType === 'SHIPPER') {
        acc.shippers.push(c)
      } else if (company.companyType === 'CARRIER') {
        acc.carriers.push(c)
      }
      return acc
    },
    { carriers: [], shippers: [] } as CompaniesAggregationType,
  ) ?? { carriers: [], shippers: [] }

  const toggleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog)
  }
  const toggleRouteDialog = () => {
    if (selectedRoute) {
      setSelectedRoute(null)
    }
    setOpenRouteDialog(!openRouteDialog)
  }

  const primaryLegs = contractRoutes?.find((cr) => cr.isPrimary)?.route?.legs
  const origin = primaryLegs?.[0]?.lane?.originName
  const destination = primaryLegs?.[primaryLegs.length - 1]?.lane?.destinationName
  const titles = [{ value: 'Contracts', to: 'contracts' }, { value: id! }]
  if (origin && destination) {
    titles.push({
      // @ts-expect-error
      value: <CompanyAvatarLink id={contract.company?.id} name={contract.company?.label} />,
    })
    titles.push({
      // @ts-expect-error
      value: (
        <Box sx={{ display: 'flex' }}>
          {origin}

          <ToIcon sx={{ marginLeft: '1em', marginRight: '1em' }} />

          {destination}
        </Box>
      ),
    })
  }

  useTitles(titles)
  useActions([
    <Button
      color='primary'
      form='contract-details-form'
      key='saveContract'
      sx={{ mr: 2 }}
      type='submit'
      variant='outlined'
    >
      Save
    </Button>,

    <Button color='error' key='deleteContract' onClick={toggleDeleteDialog} variant='contained'>
      Delete
    </Button>,
  ])

  const context = useForm<Schema>({
    // @ts-expect-error TODO: Fix later
    defaultValues: contract,
    mode: 'all',
    resolver: zr(schema),
  })

  const [
    type,
    company,
    equipmentType,
    fleet,
    volumeFrequency,
    pricingMechanism,
    allIn,
    minChargeableMiles,
    startDate,
    domicileAddress,
  ] = useWatch({
    control: context.control,
    name: [
      'type',
      'company',
      'equipmentType',
      'fleet',
      'volumeFrequency',
      'pricingMechanism',
      'allIn',
      'minChargeableMiles',
      'startDate',
      'domicileAddress',
    ],
  })

  const onTypeChange = () => {
    // @ts-expect-error
    context.setValue('company', null)
  }

  const fuelSurchargeSchedules = useMemo(() => {
    if (areFscsLoading) {
      return []
    }
    const relevant = select.fromResponses(
      fuelSurchargeSchedulesData?.filter((fsc) => {
        if (fsc.companyId === company?.id) {
          return true
        }
        if (!fsc.companyId && equipmentType && equipmentType.id === fsc.equipmentTypeId) {
          return true
        }
        return false
      }),
    )
    return relevant
  }, [areFscsLoading, company, equipmentType])

  const prevType = usePrevious(type) as typeof type
  const prevCompany = usePrevious(company) as typeof company
  const prevEquipmentType = usePrevious(equipmentType) as typeof equipmentType

  useUpdateEffect(() => {
    // Ignore RHF initialization with default values
    if (prevType === undefined && prevCompany === undefined && prevEquipmentType === undefined) {
      return
    }
    if (
      prevType !== type ||
      prevCompany?.id !== company?.id ||
      prevEquipmentType?.id !== equipmentType?.id
    ) {
      context.setValue('fuelSurchargeSchedule', null)
    }
  }, [company, equipmentType, type])

  const onSubmit = async (values: Schema) => {
    const payload = toPayload(values)
    const javalinErrorHandler = createJavalinErrorHandler(addSnackbar)

    try {
      setIsSubmitting(true)
      await restClient.put(`contracting/contracts/${contract!.id}`, payload)
      addSnackbar({
        message: [`Contract updated successfully.`].join('\n'),
      })
    } catch (error) {
      javalinErrorHandler(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDeleteRoute = (contractRoute: ContractRouteType) => async () => {
    setIsSubmitting(true)
    const javalinErrorHandler = createJavalinErrorHandler(addSnackbar)
    try {
      await restClient.delete(
        `contracting/contracts/${contract.id}/routes/${contractRoute.routeId}`,
      )
      addSnackbar({ message: 'Route deleted successfully.' })
      setContractRoutes((prev) => prev.filter((cr) => cr.routeId !== contractRoute.routeId))
    } catch (error) {
      javalinErrorHandler(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleEditRoute = (contractRoute: ContractRouteType) => () => {
    setSelectedRoute(contractRoute)
    toggleRouteDialog()
  }

  const onRouteChange = (newContractRoute: ContractRouteType, originalRouteId?: string) => {
    if (originalRouteId) {
      const newContractRoutes = contractRoutes.map((cr) => {
        if (cr.routeId === originalRouteId) {
          return newContractRoute
        }
        if (newContractRoute.isPrimary) {
          return { ...cr, isPrimary: false }
        }
        return cr
      })
      setContractRoutes(newContractRoutes)
    } else if (newContractRoute.isPrimary) {
      setContractRoutes([
        newContractRoute,
        ...contractRoutes.map((cr) => ({ ...cr, isPrimary: false })),
      ])
    } else {
      setContractRoutes([...contractRoutes, newContractRoute])
    }
  }

  return (
    <>
      <Progress loading={domicileAddressesLoading || isSubmitting} />

      <Tabs
        activeTab={activeTab}
        changeTab={setActiveTab}
        tabs={['General', 'Route', 'Matches', 'Shipments']}
      />

      {!contract.contractRoutes.length && (
        <Alert severity='error' sx={{ fontWeight: 'bold' }}>
          Contract has no route.
        </Alert>
      )}

      <FormContainer
        FormProps={{ id: 'contract-details-form' }}
        formContext={context}
        onSuccess={onSubmit}
      >
        <Page sx={{ height: 'auto' }}>
          {activeTab === 0 && (
            <Grid container item spacing={2} sx={{ padding: 2 }} xs={12}>
              <Grid item xs={12}>
                <DataRowSection label={<Text.H6>General</Text.H6>} />
              </Grid>

              <Grid item xs={2}>
                <RadioButtonGroup
                  name='type'
                  onChange={onTypeChange}
                  options={[
                    { id: 'SHIPPER', label: 'Shipper' },
                    { id: 'CARRIER', label: 'Carrier' },
                  ]}
                  row
                />
              </Grid>

              <Grid item xs={3}>
                <AutocompleteElement
                  label='Company'
                  name='company'
                  options={type === 'SHIPPER' ? companies.shippers : companies.carriers}
                  required
                  textFieldProps={{
                    fullWidth: true,
                    variant: 'standard',
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <SelectElement
                  fullWidth
                  label='Status'
                  name='status'
                  options={[
                    { id: 'DRAFT', label: 'DRAFT' },
                    { id: 'CONTRACTED', label: 'CONTRACTED' },
                    { id: 'SUGGESTED', label: 'SUGGESTED' },
                  ]}
                  required
                  variant='standard'
                />
              </Grid>

              <Grid item xs={3}>
                <TextFieldElement fullWidth label='Plan ID' name='planId' variant='standard' />
              </Grid>

              <Grid item xs={3}>
                <TextareaAutosizeElement fullWidth label='Notes' name='notes' variant='standard' />
              </Grid>

              <Grid item xs={2}>
                <CheckboxElement label='Backup Award' name='backupAward' />
              </Grid>

              <Grid item xs={4}>
                <AutocompleteElement
                  label='Equipment Type'
                  name='equipmentType'
                  options={equipmentTypes || []}
                  required
                  textFieldProps={{
                    fullWidth: true,
                    variant: 'standard',
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <AutocompleteElement
                  label='Accessorials'
                  multiple
                  name='accessorials'
                  options={accessorials || []}
                  textFieldProps={{
                    fullWidth: true,
                    variant: 'standard',
                  }}
                />
              </Grid>

              {type === 'CARRIER' && (
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={2}>
                    <CheckboxElement label='Fleet' name='fleet' />
                  </Grid>

                  {fleet && (
                    <>
                      <Grid item xs={4}>
                        <TextFieldElement
                          fullWidth
                          label='Domicile Name'
                          name='domicileName'
                          required
                          variant='standard'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <LocationAutocomplete
                          label='Domicile Address'
                          name='domicileAddress'
                          onChange={onDomicileAddressChange}
                          options={domicileAddresses.length ? domicileAddresses : [domicileAddress]}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              )}

              <Grid item xs={12}>
                <DataRowSection label={<Text.H6>Volume</Text.H6>} />
              </Grid>

              {volumeFrequency === 'WEEKLY' ? (
                <Grid item xs={2}>
                  <CheckboxElement label='Weekends' name='includeWeekends' />
                </Grid>
              ) : (
                <Grid item xs={2} />
              )}

              <Grid item xs={2}>
                <SelectElement
                  fullWidth
                  label='Volume Type'
                  name='volumeType'
                  options={[
                    { id: 'FIXED', label: 'FIXED' },
                    { id: 'VARIABLE', label: 'VARIABLE' },
                  ]}
                  required
                  variant='standard'
                />
              </Grid>

              <Grid item xs={2}>
                <SelectElement
                  fullWidth
                  label='Volume Frequency'
                  name='volumeFrequency'
                  options={[
                    { id: 'DAILY', label: 'DAILY' },
                    { id: 'WEEKLY', label: 'WEEKLY' },
                  ]}
                  required
                  variant='standard'
                />
              </Grid>

              <Grid item xs={3}>
                <DatePickerElement
                  inputProps={{
                    fullWidth: true,
                    variant: 'standard',
                  }}
                  label='Start Date'
                  name='startDate'
                />
              </Grid>

              <Grid item xs={3}>
                <DatePickerElement
                  inputProps={{
                    fullWidth: true,
                    variant: 'standard',
                  }}
                  label='End Date'
                  minDate={startDate ? new Date(startDate) : undefined}
                  name='endDate'
                />
              </Grid>

              <Grid container item spacing={2} xs={12}>
                {volumeFrequency === 'WEEKLY' && (
                  <>
                    <Grid item xs={2} />

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='Min Volume'
                        name='minVolume'
                        type='number'
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='Max Volume'
                        name='maxVolume'
                        required
                        type='number'
                        variant='standard'
                      />
                    </Grid>
                  </>
                )}

                {volumeFrequency === 'DAILY' && (
                  <>
                    <Grid item xs={2} />

                    <Grid item xs={1}>
                      <TextFieldElement label='Su' name='su' required type='number' />
                    </Grid>

                    <Grid item xs={1}>
                      <TextFieldElement label='Mo' name='mo' required type='number' />
                    </Grid>

                    <Grid item xs={1}>
                      <TextFieldElement label='Tu' name='tu' required type='number' />
                    </Grid>

                    <Grid item xs={1}>
                      <TextFieldElement label='We' name='we' required type='number' />
                    </Grid>

                    <Grid item xs={1}>
                      <TextFieldElement label='Th' name='th' required type='number' />
                    </Grid>

                    <Grid item xs={1}>
                      <TextFieldElement label='Fr' name='fr' required type='number' />
                    </Grid>

                    <Grid item xs={1}>
                      <TextFieldElement label='Sa' name='sa' required type='number' />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid container item spacing={2} xs={12}>
                {type === 'CARRIER' ? (
                  <Grid item xs={2}>
                    <CheckboxElement label='Use BTF when available' name='useBtf' />
                  </Grid>
                ) : (
                  <Grid item xs={2} />
                )}

                <Grid item xs={12}>
                  <DataRowSection label={<Text.H6>Pricing</Text.H6>} />
                </Grid>

                {pricingMechanism === 'INDEX' ? (
                  <Grid item xs={2}>
                    <CheckboxElement label='All-in' name='allIn' />
                  </Grid>
                ) : (
                  <Grid item xs={2} />
                )}

                <Grid item xs={2}>
                  <SelectElement
                    fullWidth
                    label='Pricing Mechanism'
                    name='pricingMechanism'
                    options={[
                      { id: 'FIXED', label: 'FIXED' },
                      { id: 'INDEX', label: 'INDEX' },
                      { id: 'OPEN_BOOK', label: 'OPEN_BOOK' },
                    ]}
                    required
                    variant='standard'
                  />
                </Grid>

                {pricingMechanism === 'OPEN_BOOK' && (
                  <Grid item xs={2}>
                    <TextFieldElement
                      fullWidth
                      label='Carrier Upcharge (%)'
                      name='carrierUpcharge'
                      type='number'
                      variant='standard'
                    />
                  </Grid>
                )}

                {pricingMechanism === 'FIXED' && (
                  <>
                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='Fixed Fee Per Shipment'
                        name='fixedFeePerShipment'
                        type='number'
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='LH RPM'
                        name='linehaulRpm'
                        type='number'
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='Min LH Charge'
                        name='minLinehaulCharge'
                        type='number'
                        variant='standard'
                      />
                    </Grid>
                  </>
                )}

                {pricingMechanism === 'INDEX' && (
                  <>
                    <Grid item xs={2}>
                      <SelectElement
                        fullWidth
                        label='Pricing Index'
                        name='pricingIndex'
                        options={[{ id: 'DAT', label: 'DAT' }]}
                        required
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='PI Premium (%)'
                        name='pricingIndexPremium'
                        type='number'
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='PI Override Sundays (%)'
                        name='pricingIndexPremiumOverrideSunday'
                        type='number'
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='Min Tender Lead (hours)'
                        name='minTenderLeadTime'
                        type='number'
                        variant='standard'
                      />
                    </Grid>
                  </>
                )}

                {type === 'CARRIER' && pricingMechanism !== 'INDEX' && (
                  <Grid container item spacing={2} xs={12}>
                    <Grid item xs={2} />

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='Rate per Asset'
                        name='assetPricing'
                        type='number'
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <SelectElement
                        fullWidth
                        label='Asset Pricing Frequency'
                        name='assetPricingFrequency'
                        options={[
                          { id: 'DAILY', label: 'DAILY' },
                          { id: 'WEEKLY', label: 'WEEKLY' },
                        ]}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextFieldElement
                        fullWidth
                        label='Min Chargeable Miles'
                        name='minChargeableMiles'
                        type='number'
                        variant='standard'
                      />
                    </Grid>

                    {minChargeableMiles && (
                      <Grid item xs={3}>
                        <SelectElement
                          fullWidth
                          label='Min Chargeable Miles Frequency'
                          name='minChargeableMilesFrequency'
                          options={[
                            { id: 'DAILY', label: 'DAILY' },
                            { id: 'WEEKLY', label: 'WEEKLY' },
                          ]}
                          required
                          variant='standard'
                        />
                      </Grid>
                    )}
                  </Grid>
                )}

                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={2} />

                  {(pricingMechanism !== 'INDEX' || (pricingMechanism === 'INDEX' && !allIn)) && (
                    <Grid item xs={4}>
                      <AutocompleteElement
                        label='FSC'
                        name='fuelSurchargeSchedule'
                        options={fuelSurchargeSchedules}
                        required={!allIn}
                        textFieldProps={{
                          fullWidth: true,
                          helperText:
                            'Select a company and an equipment type to see FSCs or go all-in with INDEX pricing',
                          variant: 'standard',
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {activeTab === 1 && (
            <Grid container item spacing={2} sx={{ padding: 2 }} xs={12}>
              <Grid container item spacing={2} xs={6}>
                {contractRoutes.map((contractRoute, i) => {
                  const from = contractRoute.route.legs[0].lane.originName
                  const to =
                    contractRoute.route.legs[contractRoute.route.legs.length - 1].lane
                      .destinationName

                  return (
                    <SubCard
                      actions={
                        <SubMenu
                          items={[
                            {
                              icon: RouteEditIcon,
                              onClick: handleEditRoute(contractRoute),
                              value: 'Edit',
                            },
                            {
                              icon: DeleteIcon,
                              onClick: handleDeleteRoute(contractRoute),
                              value: 'Delete',
                            },
                          ]}
                        />
                      }
                      key={contractRoute.routeId}
                      // @ts-expect-error
                      title={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Text.H6>{from}</Text.H6>

                          <ToIcon sx={{ marginLeft: '1em', marginRight: '1em' }} />

                          <Text.H6>{to}</Text.H6>

                          {contractRoute.isPrimary && (
                            <Tooltip sx={{ marginLeft: 2 }} title='Primary route'>
                              <PrimaryIcon color='primary' />
                            </Tooltip>
                          )}
                        </Box>
                      }
                    >
                      <Select
                        fullWidth
                        id='moveType'
                        label='Move Type'
                        readOnly
                        required
                        value={contractRoute.route.tripType}
                        variant='standard'
                      >
                        {[
                          { id: 'ONE_WAY', label: 'ONE_WAY' },
                          { id: 'ROUND_TRIP', label: 'ROUND_TRIP' },
                          { id: 'CIRCUIT', label: 'CIRCUIT' },
                          { id: 'CONTINUOUS_MOVE', label: 'CONTINUOUS_MOVE' },
                          { id: 'SHORTY', label: 'SHORTY' },
                        ].map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>

                      {contractRoute.route.legs?.map((leg, j) => (
                        <Box
                          key={leg.lane.id}
                          sx={{
                            display: 'flex',
                            marginBottom: '48px',
                            position: 'relative',
                          }}
                        >
                          <Route.RouteStyled
                            $color={color.lane(i)}
                            $position={j * 2 + 1}
                            sx={{ marginLeft: '1.5em' }}
                          />

                          <Route.LaneStyled>
                            <Typography variant='body2'>{leg.lane.originName}</Typography>

                            <Box>
                              <Route.DistanceStyled>
                                <Box gap={8} sx={{ display: 'flex' }}>
                                  {leg.lane.leafMiles ? (
                                    <Typography variant='caption'>
                                      {leg.lane.leafMiles} miles
                                    </Typography>
                                  ) : (
                                    <Typography variant='caption'>Unknown miles</Typography>
                                  )}
                                </Box>

                                <Route.HorizontalLineStyled />

                                <Box sx={{ marginLeft: '2', marginRight: '2' }}>
                                  <CompanyAvatar id={leg.lane.shipper?.id} />
                                </Box>
                              </Route.DistanceStyled>
                            </Box>

                            <Typography variant='body2'>{leg.lane.destinationName}</Typography>
                          </Route.LaneStyled>
                        </Box>
                      ))}
                    </SubCard>
                  )
                })}

                <Grid
                  item
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  xs={12}
                >
                  <Tooltip placement='top' title='Add Route'>
                    <IconButton onClick={toggleRouteDialog}>
                      <CreateIcon sx={{ fontSize: '4em' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container item spacing={2} xs={6}>
                <SubCard sx={{ padding: '0 !important' }}>
                  <Grid item sx={{ height: '600px' }} xs={12}>
                    <Map
                      fitBounds={contractRoutes.flatMap((cr) =>
                        cr.route.legs.map((leg) => leg.lane.path),
                      )}
                      mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY}
                    >
                      {contractRoutes.flatMap((contractRoute, i) =>
                        contractRoute.route.legs?.map((leg, j) => (
                          <Route.MapLane
                            color={color.lane(i)}
                            geometry={leg.lane.path}
                            id={j.toString()}
                            key={leg.lane.id}
                            position={j * 2}
                          />
                        )),
                      )}
                    </Map>
                  </Grid>
                </SubCard>
              </Grid>
            </Grid>
          )}

          {activeTab === 2 && (
            <SubCard sx={{ height: CONTAINER_TABLE_HEIGHT, padding: 0 }}>
              <ContractDetailsMatches data={matches} loading={isMatchesLoading} />
            </SubCard>
          )}

          {activeTab === 3 && (
            <SubCard sx={{ height: CONTAINER_TABLE_HEIGHT, padding: 0 }}>
              <ContractDetailsShipments data={shipments || []} loading={isShipmentsLoading} />
            </SubCard>
          )}
        </Page>
      </FormContainer>

      <ContractDetailsDeleteDialog
        ids={{ contract: contract.id, routes: contract.contractRoutes.map((cr) => cr.routeId) }}
        onClose={toggleDeleteDialog}
        open={openDeleteDialog}
      />

      {openRouteDialog && (
        <ContractDetailsRouteDialog
          companyId={contract.company!.id}
          contractId={contract.id}
          contractRoute={selectedRoute}
          isShipper={contract.type === 'SHIPPER'}
          onClose={toggleRouteDialog}
          onSuccess={onRouteChange}
          open={openRouteDialog}
        />
      )}
    </>
  )
}

export { ContractDetails, getLocation }
