// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetExecutionLocationDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetExecutionLocationDetailsQuery = { __typename?: 'query_root', executionLocationByPk?: { __typename?: 'ExecutionLocation', id: number, businessName?: string, street?: string, city?: string, state?: string, zip?: string, country?: string, timezone?: string, geo?: any, shipperLocationName?: string, shipperLocationReference?: string, locationType?: string, contactName?: string, contactEmail?: string, contactPhone?: string, operatingHours?: string, accessRequirements?: string, appointmentRequirements?: string, trailerPoolStorageRequirements?: string, createdAt?: any, lastUpdatedAt?: any, company: { __typename?: 'companies', id: any, name: string } } };

export type UpdateExecutionLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  executionLocation: Types.ExecutionLocationSetInput;
}>;


export type UpdateExecutionLocationMutation = { __typename?: 'mutation_root', updateExecutionLocationByPk?: { __typename?: 'ExecutionLocation', id: number } };



export const GetExecutionLocationDetailsDocument = `
    query GetExecutionLocationDetails($id: Int!) @cached(ttl: 300) {
  executionLocationByPk(id: $id) {
    id
    company {
      id
      name
    }
    businessName
    street
    city
    state
    zip
    country
    timezone
    geo
    shipperLocationName
    shipperLocationReference
    locationType
    contactName
    contactEmail
    contactPhone
    operatingHours
    accessRequirements
    appointmentRequirements
    trailerPoolStorageRequirements
    createdAt
    lastUpdatedAt
  }
}
    `;

export const useGetExecutionLocationDetailsQuery = <
      TData = GetExecutionLocationDetailsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetExecutionLocationDetailsQueryVariables,
      options?: UseQueryOptions<GetExecutionLocationDetailsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetExecutionLocationDetailsQuery, TError, TData>(
      ['GetExecutionLocationDetails', variables],
      fetcher<GetExecutionLocationDetailsQuery, GetExecutionLocationDetailsQueryVariables>(client, GetExecutionLocationDetailsDocument, variables, headers),
      options
    )};

useGetExecutionLocationDetailsQuery.document = GetExecutionLocationDetailsDocument;

useGetExecutionLocationDetailsQuery.getKey = (variables: GetExecutionLocationDetailsQueryVariables) => ['GetExecutionLocationDetails', variables];


useGetExecutionLocationDetailsQuery.fetcher = (client: GraphQLClient, variables: GetExecutionLocationDetailsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetExecutionLocationDetailsQuery, GetExecutionLocationDetailsQueryVariables>(client, GetExecutionLocationDetailsDocument, variables, headers);

export const UpdateExecutionLocationDocument = `
    mutation updateExecutionLocation($id: Int!, $executionLocation: ExecutionLocationSetInput!) {
  updateExecutionLocationByPk(pkColumns: {id: $id}, _set: $executionLocation) {
    id
  }
}
    `;

export const useUpdateExecutionLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateExecutionLocationMutation, TError, UpdateExecutionLocationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateExecutionLocationMutation, TError, UpdateExecutionLocationMutationVariables, TContext>(
      ['updateExecutionLocation'],
      (variables?: UpdateExecutionLocationMutationVariables) => fetcher<UpdateExecutionLocationMutation, UpdateExecutionLocationMutationVariables>(client, UpdateExecutionLocationDocument, variables, headers)(),
      options
    )};

useUpdateExecutionLocationMutation.getKey = () => ['updateExecutionLocation'];


useUpdateExecutionLocationMutation.fetcher = (client: GraphQLClient, variables: UpdateExecutionLocationMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateExecutionLocationMutation, UpdateExecutionLocationMutationVariables>(client, UpdateExecutionLocationDocument, variables, headers);
