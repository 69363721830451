import type { LoaderFunctionArgs } from 'react-router-dom'

import { queryClient } from '@/api'
import { executionLocationDetailsQuery } from '@/features/execution-location/execution-location-details.api'

const executionLocationDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const query = executionLocationDetailsQuery(params.id!)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export { executionLocationDetailsLoader }
