// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetContractShipmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type GetContractShipmentsQuery = { __typename?: 'query_root', shipmentTenders: Array<{ __typename?: 'shipmentTenders', id: any, shipmentExecutionStatus: string, poNumber?: string, legNumber?: number, originAppointmentTime?: any, originInitialAppointmentTime?: any, expectedShipmentDate?: any, destination?: { __typename?: 'ExecutionLocation', shipperLocationName?: string, shipperLocationReference?: string, businessName?: string, city?: string, state?: string, street?: string, zip?: string }, origin?: { __typename?: 'ExecutionLocation', shipperLocationName?: string, shipperLocationReference?: string, businessName?: string, city?: string, state?: string, street?: string, zip?: string }, equipmentType?: { __typename?: 'equipmentTypes', name: string }, carrierTripTender?: { __typename?: 'carrierTripTenders', tripId: string, carrier?: { __typename?: 'companies', id: any, name: string } }, company: { __typename?: 'companies', id: any, name: string } }> };



export const GetContractShipmentsDocument = `
    query GetContractShipments($id: uuid!) {
  shipmentTenders(
    where: {_and: [{buyContractId: {_eq: $id}}]}
    orderBy: {createdAt: DESC}
  ) {
    id
    destination {
      shipperLocationName
      shipperLocationReference
      businessName
      city
      state
      street
      zip
    }
    origin {
      shipperLocationName
      shipperLocationReference
      businessName
      city
      state
      street
      zip
    }
    shipmentExecutionStatus
    equipmentType {
      name
    }
    carrierTripTender {
      tripId
      carrier {
        id
        name
      }
    }
    poNumber
    legNumber
    originAppointmentTime
    originInitialAppointmentTime
    expectedShipmentDate
    company {
      id
      name
    }
  }
}
    `;

export const useGetContractShipmentsQuery = <
      TData = GetContractShipmentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetContractShipmentsQueryVariables,
      options?: UseQueryOptions<GetContractShipmentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetContractShipmentsQuery, TError, TData>(
      ['GetContractShipments', variables],
      fetcher<GetContractShipmentsQuery, GetContractShipmentsQueryVariables>(client, GetContractShipmentsDocument, variables, headers),
      options
    )};

useGetContractShipmentsQuery.document = GetContractShipmentsDocument;

useGetContractShipmentsQuery.getKey = (variables: GetContractShipmentsQueryVariables) => ['GetContractShipments', variables];


useGetContractShipmentsQuery.fetcher = (client: GraphQLClient, variables: GetContractShipmentsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetContractShipmentsQuery, GetContractShipmentsQueryVariables>(client, GetContractShipmentsDocument, variables, headers);
