/* eslint-disable perfectionist/sort-object-types */
import type { ReactNode } from 'react'

/* eslint-disable perfectionist/sort-objects */
import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type { SnackbarType, StackType } from '@leaf/components'
import type * as Types from '@leaf/types'

import type { Title } from '@/types'

type GlobalStore = {
  user: Types.User
  titles: Title[]
  actions: ReactNode[]
  snackbars: StackType
  showChangelog: boolean
  showDrawer: boolean

  // Tables
  adaptLaneOverview: object
  adaptNetworkMoveOverview: object
  adaptConstellationOverview: object
  analyticsLaneOverview: object
  companyOverview: object
  equipmentTypeOverview: object
  fuelSurchargeScheduleOverview: object
  lanePlanOverview: object
  userOverview: object
  contractOverview: object
  contractDetailsMatches: object
  contractDetailsShipments: object
  fuelPriceOverview: object
  fuelPriceIndexOverview: object
  shipmentOverview: object
  executionLaneOverview: object
  executionLocationOverview: object

  updateUser: (user: object) => void
  updateTitles: (titles: Title[]) => void
  updateActions: (actions: ReactNode[]) => void
  addSnackbar: (snackbar: SnackbarType) => void
  removeSnackbar: (id: string) => void
  toggleChangelog: () => void
  toggleDrawer: () => void
  changeTable: (name: string, data: object) => void
}

const useStore = create<GlobalStore>()(
  // Everything will be persisted to local storage, except the stuff explicitly in the blacklist
  persist(
    (set) => {
      return {
        user: {},
        titles: [],
        actions: [],
        snackbars: {},
        showChangelog: true,
        showDrawer: true,

        // Tables
        adaptLaneOverview: {},
        adaptNetworkMoveOverview: {},
        adaptConstellationOverview: {},
        analyticsLaneOverview: {},
        companyAdaptLanes: {},
        companyOverview: {},
        equipmentTypeOverview: {},
        fuelSurchargeScheduleOverview: {},
        lanePlanOverview: {},
        userOverview: {},
        contractOverview: {},
        contractDetailsMatches: {},
        contractDetailsShipments: {},
        fuelPriceOverview: {},
        fuelPriceIndexOverview: {},
        shipmentOverview: {},
        executionLaneOverview: {},
        executionLocationOverview: {},

        updateUser: (u) => {
          return set(
            produce((state) => {
              state.user = u
            }),
          )
        },
        updateTitles: (t: Title[]) => {
          return set(
            produce((state) => {
              state.titles = t
            }),
          )
        },
        updateActions: (a: ReactNode[]) => {
          return set(
            produce((state) => {
              state.actions = a
            }),
          )
        },
        addSnackbar: (s: SnackbarType) => {
          return set(
            produce((state) => {
              const id = uuid()
              state.snackbars[id] = {
                autoHideDuration: 3000,
                severity: 'success',
                ...s,
              }
            }),
          )
        },
        removeSnackbar: (id: string) => {
          return set(
            produce((state) => {
              delete state.snackbars[id]
            }),
          )
        },
        toggleChangelog: () => {
          return set(
            produce((state) => {
              state.showChangelog = !state.showChangelog
            }),
          )
        },
        toggleDrawer: () => {
          return set(
            produce((state) => {
              state.showDrawer = !state.showDrawer
            }),
          )
        },
        changeTable: (name: string, data: object) => {
          return set(
            produce((state) => {
              state[name] = data
            }),
          )
        },
      }
    },
    {
      name: 'global',
      partialize: (state) => {
        const blacklist = ['snackbars', 'user', 'titles', 'actions']
        const partials = Object.entries(state).filter(([key]) => !blacklist.includes(key))
        const entries = Object.fromEntries(partials)
        return entries
      },
    },
  ),
)

export { useStore }
