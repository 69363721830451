import { useEffect, useState } from 'react'

import { zodResolver as zr } from '@hookform/resolvers/zod'
import { Square } from '@mui/icons-material'
import { Box, Grid, List, ListItem, styled } from '@mui/material'
import { FormContainer, useForm, useWatch } from 'react-hook-form-mui'
import { Layer, Popup, Source } from 'react-map-gl'
import * as z from 'zod'

import { Map, Page, Progress, Text, runAnimation } from '@leaf/components'
import { LocationAutocomplete, useLocations } from '@leaf/form'
import { color as colorUtils, geometry, omitStyleProp } from '@leaf/utilities'

import { restClient } from '@/api'
import { useTitles } from '@/hooks'
import { useStore } from '@/store'

type LocationType = {
  address: string
  addressType: string
  city: string
  latitude: number
  longitude: number
  state: string
  zipCode: string
}

type RouteType = {
  distance: number
  drivingTimeMinutes: number
  geometry: GeoJSON.MultiLineString
}

const schema = z.object({
  destination: z.any(),
  origin: z.any(),
})
type Schema = z.infer<typeof schema>

const PopupStyled = styled(Popup, omitStyleProp)<{ $color: string }>`
  & .mapboxgl-popup-content {
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    padding: 0;
    text-align: center;

    & p {
      color: ${(props) => props.$color};
      font-weight: bold;
      text-shadow:
        -1px 0 white,
        0 1px white,
        1px 0 white,
        0 -1px white;
      letter-spacing: 0.1em;
    }
  }

  & .mapboxgl-popup-tip {
    display: none;
  }
`

const getPopup = (lat: number, lng: number, location: LocationType, color: string) => {
  if (!lat || !lng || !location) {
    return null
  }
  return (
    <PopupStyled
      $color={color}
      anchor='top'
      closeButton={false}
      closeOnClick={false}
      closeOnMove={false}
      latitude={lat}
      longitude={lng}
    >
      <Text.Body1>{location.address}</Text.Body1>

      <Text.Body1>
        {location.city}, {location.state} {location.zipCode}
      </Text.Body1>
    </PopupStyled>
  )
}

const PCM = () => {
  const addSnackbar = useStore((state) => state.addSnackbar)

  const [routes, setRoutes] = useState<RouteType[]>([])
  const [loading, setLoading] = useState(false)

  const onLocationError = () =>
    addSnackbar({ message: 'Failed to find location', severity: 'error' })
  const {
    loading: originsLoading,
    locations: origins,
    onLocationChange: onOriginLocationChange,
  } = useLocations(restClient, onLocationError)
  const {
    loading: destinationsLoading,
    locations: destinations,
    onLocationChange: onDestinationLocationChange,
  } = useLocations(restClient, onLocationError)

  useTitles([{ value: 'PC*MILER' }])

  const context = useForm<Schema>({
    mode: 'all',
    resolver: zr(schema),
  })

  const [origin, destination] = useWatch({
    control: context.control,
    name: ['origin', 'destination'],
  })

  useEffect(() => {
    if (origin && destination) {
      const stops = `${origin.longitude},${origin.latitude};${destination.longitude},${destination.latitude}`

      setLoading(true)

      restClient
        .get(`geo/driving-routes?stops=${stops}`)
        .then((response) => {
          setRoutes(response.data)
        })
        .catch(() => addSnackbar({ message: 'Failed to find route', severity: 'error' }))
        .finally(() => {
          setLoading(false)
        })
    } else {
      setRoutes([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, destination])

  const colors = routes.map((_, i) => {
    return colorUtils.lane(i)
  })

  const coordinates = routes[0]?.geometry?.coordinates[0]
  const lastCoordinates = coordinates && coordinates.length - 1

  const mapLaneIds = routes.map((_, i) => `lane-${i}-dash`)
  const onIdle = runAnimation(mapLaneIds)

  return (
    <FormContainer formContext={context}>
      <Progress loading={loading || originsLoading || destinationsLoading} />

      <Page>
        <Grid item xs={6}>
          <LocationAutocomplete
            label='Origin'
            name='origin'
            onChange={onOriginLocationChange}
            options={origins}
          />
        </Grid>

        <Grid item xs={6}>
          <LocationAutocomplete
            label='Destination'
            name='destination'
            onChange={onDestinationLocationChange}
            options={destinations}
          />
        </Grid>

        <Grid item sx={{ height: '600px' }} xs={12}>
          <Map mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_KEY} onIdle={onIdle}>
            {routes.length &&
              routes.map((route, i) => {
                return (
                  <Source
                    data={geometry.asFeatureCollection([route.geometry])}
                    id={`route-${i}`}
                    key={`route-${i}`}
                    type='geojson'
                  >
                    <Layer
                      id={`lane-${i}-background`}
                      paint={{
                        'line-color': colors[i],
                        'line-opacity': 0.4,
                        'line-width': 5,
                      }}
                      type='line'
                    />

                    <Layer
                      id={`lane-${i}-dash`}
                      paint={{
                        'line-color': colors[i],
                        'line-dasharray': [0, 4, 3],
                        'line-width': 5,
                      }}
                      type='line'
                    />
                  </Source>
                )
              })}

            {coordinates && getPopup(coordinates[0][1], coordinates[0][0], origin, 'green')}

            {coordinates &&
              getPopup(
                coordinates[lastCoordinates][1],
                coordinates[lastCoordinates][0],
                destination,
                'red',
              )}

            <Box>
              <List>
                {routes.map((route, i) => {
                  return (
                    <ListItem>
                      <Square sx={{ color: colors[i] }} />
                      Route {i + 1}: {route.distance} miles, {route.drivingTimeMinutes} minutes
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          </Map>
        </Grid>
      </Page>
    </FormContainer>
  )
}

export { PCM }
