import { useShallow } from 'zustand/react/shallow'

import { Columns, Table, renderCompanyAvatar } from '@leaf/table'

import { TABLE_HEIGHT } from '@/constants'
import type { ShipmentType } from '@/features/contract/contract-details.types'
import { useStore } from '@/store'

const getRowId = ({ id }: { id: string }) => `shipments/${id}`

type ContractDetailsShipmentsProps = {
  data: ShipmentType[]
  loading: boolean
}

const ContractDetailsShipments = ({
  data,
  loading,
}: ContractDetailsShipmentsProps) => {
  const [changeTable, savedState] = useStore(
    useShallow((state) => [state.changeTable, state.contractDetailsShipments]),
  )

  const columns = [
    Columns.Action(),
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'company.name',
      headerName: 'Shipper',
      minWidth: 200,
      renderCell: renderCompanyAvatar({
        id: 'company.id',
        name: 'company.name',
      }),
    },
    {
      field: 'poNumber',
      headerName: 'Reference',
    },
    {
      field: 'pickupDate',
      headerName: 'Pickup Date',
      type: 'date',
    },
    {
      field: 'origin',
      headerName: 'Origin',
      minWidth: 400,
      type: 'list',
    },
    {
      field: 'destination',
      headerName: 'Destination',
      minWidth: 400,
      type: 'list',
    },
    {
      field: 'legNumber',
      headerName: 'Leg',
      type: 'number',
    },
    {
      field: 'carrierTripTender.tripId',
      headerName: 'Trip ID',
    },
    {
      field: 'carrierTripTender.carrier.name',
      headerName: 'Carrier',
      minWidth: 200,
      renderCell: renderCompanyAvatar({
        id: 'carrierTripTender.carrier.id',
        name: 'carrierTripTender.carrier.name',
      }),
    },
    {
      field: 'equipmentType.name',
      headerName: 'Equipment',
    },
    {
      field: 'shipmentExecutionStatus',
      headerName: 'Status',
    },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: true,
        legNumber: false,
      },
    },
  }

  return (
    <Table
      // @ts-expect-error
      columns={columns}
      disableColumnMenu
      getRowId={getRowId}
      height={TABLE_HEIGHT}
      initialState={initialState}
      loading={loading}
      name='contractDetailsShipments'
      persist={{
        fn: changeTable,
        save: savedState,
      }}
      rows={data}
      slots={{
        toolbar: null,
      }}
    />
  )
}

export { ContractDetailsShipments }
