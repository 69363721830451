// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetShipmentsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  orderBy?: Types.InputMaybe<Array<Types.ShipmentTendersOrderBy> | Types.ShipmentTendersOrderBy>;
  where?: Types.InputMaybe<Types.ShipmentTendersBoolExp>;
}>;


export type GetShipmentsQuery = { __typename?: 'query_root', shipmentTenders: Array<{ __typename?: 'shipmentTenders', id: any, shipmentExecutionStatus: string, poNumber?: string, legNumber?: number, originAppointmentTime?: any, originInitialAppointmentTime?: any, expectedShipmentDate?: any, shipperShipmentId?: string, transportationMode?: string, destination?: { __typename?: 'ExecutionLocation', shipperLocationName?: string, businessName?: string, city?: string, state?: string, street?: string, zip?: string }, origin?: { __typename?: 'ExecutionLocation', shipperLocationName?: string, businessName?: string, city?: string, state?: string, street?: string, zip?: string }, equipmentType?: { __typename?: 'equipmentTypes', name: string }, carrierTripTender?: { __typename?: 'carrierTripTenders', tripId: string, carrier?: { __typename?: 'companies', id: any, name: string } }, company: { __typename?: 'companies', id: any, name: string } }>, shipmentTendersAggregate: { __typename?: 'shipmentTendersAggregate', aggregate?: { __typename?: 'shipmentTendersAggregateFields', count: number } } };



export const GetShipmentsDocument = `
    query GetShipments($limit: Int!, $offset: Int!, $orderBy: [shipmentTendersOrderBy!], $where: shipmentTendersBoolExp) @cached(ttl: 300) {
  shipmentTenders(
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    where: $where
  ) {
    id
    destination {
      shipperLocationName
      businessName
      city
      state
      street
      zip
    }
    origin {
      shipperLocationName
      businessName
      city
      state
      street
      zip
    }
    shipmentExecutionStatus
    equipmentType {
      name
    }
    carrierTripTender {
      tripId
      carrier {
        id
        name
      }
    }
    poNumber
    legNumber
    originAppointmentTime
    originInitialAppointmentTime
    expectedShipmentDate
    company {
      id
      name
    }
    shipperShipmentId
    transportationMode
  }
  shipmentTendersAggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

export const useGetShipmentsQuery = <
      TData = GetShipmentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetShipmentsQueryVariables,
      options?: UseQueryOptions<GetShipmentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetShipmentsQuery, TError, TData>(
      ['GetShipments', variables],
      fetcher<GetShipmentsQuery, GetShipmentsQueryVariables>(client, GetShipmentsDocument, variables, headers),
      options
    )};

useGetShipmentsQuery.document = GetShipmentsDocument;

useGetShipmentsQuery.getKey = (variables: GetShipmentsQueryVariables) => ['GetShipments', variables];


useGetShipmentsQuery.fetcher = (client: GraphQLClient, variables: GetShipmentsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetShipmentsQuery, GetShipmentsQueryVariables>(client, GetShipmentsDocument, variables, headers);
