// @ts-nocheck
/* eslint-disable */
import type * as Types from '../../api/hasura.types.generated';

import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, type UseQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetExecutionLaneDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetExecutionLaneDetailsQuery = { __typename?: 'query_root', executionLanesByPk?: { __typename?: 'executionLanes', id: number, geo?: any, leafDistance: number, shipperDistance?: any, referenceRpm?: any, co2ReductionPerShipment?: any, tolls?: any, drivingTimeMinutes?: any, createdAt?: any, lastUpdatedAt?: any, shipper: { __typename?: 'companies', id: any, name: string }, origin: { __typename?: 'ExecutionLocation', id: number, city?: string, state?: string, zip?: string, street?: string, businessName?: string, shipperLocationName?: string, shipperLocationReference?: string }, destination: { __typename?: 'ExecutionLocation', id: number, city?: string, state?: string, zip?: string, street?: string, businessName?: string, shipperLocationName?: string, shipperLocationReference?: string } } };

export type UpdateExecutionLaneMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  executionLane: Types.ExecutionLanesSetInput;
}>;


export type UpdateExecutionLaneMutation = { __typename?: 'mutation_root', updateExecutionLanesByPk?: { __typename?: 'executionLanes', id: number } };



export const GetExecutionLaneDetailsDocument = `
    query GetExecutionLaneDetails($id: Int!) @cached(ttl: 300) {
  executionLanesByPk(id: $id) {
    id
    shipper {
      id
      name
    }
    origin {
      id
      city
      state
      zip
      street
      businessName
      shipperLocationName
      shipperLocationReference
    }
    destination {
      id
      city
      state
      zip
      street
      businessName
      shipperLocationName
      shipperLocationReference
    }
    geo
    leafDistance
    shipperDistance
    referenceRpm
    co2ReductionPerShipment
    tolls
    drivingTimeMinutes
    createdAt
    lastUpdatedAt
  }
}
    `;

export const useGetExecutionLaneDetailsQuery = <
      TData = GetExecutionLaneDetailsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetExecutionLaneDetailsQueryVariables,
      options?: UseQueryOptions<GetExecutionLaneDetailsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetExecutionLaneDetailsQuery, TError, TData>(
      ['GetExecutionLaneDetails', variables],
      fetcher<GetExecutionLaneDetailsQuery, GetExecutionLaneDetailsQueryVariables>(client, GetExecutionLaneDetailsDocument, variables, headers),
      options
    )};

useGetExecutionLaneDetailsQuery.document = GetExecutionLaneDetailsDocument;

useGetExecutionLaneDetailsQuery.getKey = (variables: GetExecutionLaneDetailsQueryVariables) => ['GetExecutionLaneDetails', variables];


useGetExecutionLaneDetailsQuery.fetcher = (client: GraphQLClient, variables: GetExecutionLaneDetailsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetExecutionLaneDetailsQuery, GetExecutionLaneDetailsQueryVariables>(client, GetExecutionLaneDetailsDocument, variables, headers);

export const UpdateExecutionLaneDocument = `
    mutation updateExecutionLane($id: Int!, $executionLane: executionLanesSetInput!) {
  updateExecutionLanesByPk(pkColumns: {id: $id}, _set: $executionLane) {
    id
  }
}
    `;

export const useUpdateExecutionLaneMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateExecutionLaneMutation, TError, UpdateExecutionLaneMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateExecutionLaneMutation, TError, UpdateExecutionLaneMutationVariables, TContext>(
      ['updateExecutionLane'],
      (variables?: UpdateExecutionLaneMutationVariables) => fetcher<UpdateExecutionLaneMutation, UpdateExecutionLaneMutationVariables>(client, UpdateExecutionLaneDocument, variables, headers)(),
      options
    )};

useUpdateExecutionLaneMutation.getKey = () => ['updateExecutionLane'];


useUpdateExecutionLaneMutation.fetcher = (client: GraphQLClient, variables: UpdateExecutionLaneMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateExecutionLaneMutation, UpdateExecutionLaneMutationVariables>(client, UpdateExecutionLaneDocument, variables, headers);
