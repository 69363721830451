import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { Spinner, Tabs } from '@leaf/components'
import { useTabs } from '@leaf/hooks'

import { restClient } from '@/api'
import { useTitles } from '@/hooks'

type Dashboard = {
  ec: string
  name: string
  token: string
}

type Category = {
  category: string
  tokens: Dashboard[]
}

const DashboardCategory = () => {
  const navigate = useNavigate()
  const { category } = useParams()
  const [dashboards, setDashboards] = useState<Dashboard[]>([])

  const { activeTab, setActiveTab } = useTabs()
  useTitles([{ value: 'Dashboards' }, { value: category! }])

  const { data, isLoading } = useQuery({
    queryFn: () => restClient.get('tendering/holistics'),
    queryKey: ['holistics'],
  })

  useEffect(() => {
    if (!isLoading) {
      const categories = data!.data as Category[]
      if (!category) {
        navigate(`/dashboard/${categories[0].category}`)
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const c of categories) {
        if (c.category === category) {
          setDashboards(c.tokens)
          setActiveTab(0)
          return
        }
      }
      // No matching category - go to first defined category
      navigate(`/dashboard/${categories[0].category}`)
    }
  }, [isLoading, category])

  return dashboards.length < 1 ? (
    <Spinner page />
  ) : (
    <>
      {dashboards.length > 1 && (
        <Tabs activeTab={activeTab} changeTab={setActiveTab} tabs={dashboards.map((d) => d.name)} />
      )}

      <iframe
        allowFullScreen
        src={`https://us.holistics.io/embed/${dashboards[activeTab].ec}?_token=${dashboards[activeTab].token}`}
        style={{
          border: '0',
          height: 'calc(100% - 54px)',
          width: '100%',
        }}
        title={dashboards[activeTab].name}
      />
    </>
  )
}

export { DashboardCategory }
