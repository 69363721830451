import { Navigate, redirect } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

import { Sentry } from '@leaf/integration'

import {
  AdaptConstellationDetails,
  AdaptConstellationOverview,
  AdaptLaneOverview,
  AdaptNetworkMoveDetails,
  AdaptNetworkMoveOverview,
  AnalyticsLaneOverview,
  CompanyDetails,
  CompanyOverview,
  ContractDetails,
  ContractDetailsShipmentsDetails,
  ContractNetwork,
  ContractOverview,
  DashboardCategory,
  EquipmentTypeDetails,
  EquipmentTypeOverview,
  ExecutionLaneDetails,
  ExecutionLaneOverview,
  FuelPriceIndexOverview,
  FuelPriceOverview,
  FuelSurchargeScheduleDetails,
  FuelSurchargeScheduleOverview,
  LaneExplorerView,
  LanePlanDetails,
  LanePlanOverview,
  PCM,
  ShipmentOverview,
  ShipperNetwork,
  UserDetails,
  UserOverview,
  adaptConstellationDetailsLoader,
  adaptNetworkMoveDetailsLoader,
  companyDetailsLoader,
  contractDetailsLoader,
  equipmentTypeDetailsLoader,
  executionLaneDetailsLoader,
  fuelSurchargeDetailsLoader,
  lanePlanDetailsLoader,
  shipmentDetailsLoader,
  userDetailsLoader,
} from '@/features'
import { ExecutionLocationDetails } from '@/features/execution-location/execution-location-details'
import { executionLocationDetailsLoader } from '@/features/execution-location/execution-location-details.loader'
import { ExecutionLocationOverview } from '@/features/execution-location/execution-location-overview'
import { ErrorPage } from '@/navigation/pages/error-page'
import { LayoutPage } from '@/navigation/pages/layout-page'
import { NotFoundPage } from '@/navigation/pages/not-found-page'

const DEFAULT_ROUTE = '/dashboard'

const publicRoutes: never[] = []

// Redirects for renamed routes, delete after 3 months
const redirectRoutes: never[] = []

const privateRoutes: RouteObject[] = [
  { element: <Navigate to={DEFAULT_ROUTE} />, path: '/' },

  // Dashboard
  {
    Component: DashboardCategory,
    path: 'dashboard',
  },
  {
    Component: DashboardCategory,
    path: 'dashboard/:category',
  },

  // Accounts
  {
    children: [
      {
        Component: CompanyOverview,
        index: true,
      },
      {
        Component: CompanyDetails,
        loader: companyDetailsLoader,
        path: ':id',
      },
    ],
    path: 'companies',
  },
  {
    children: [
      {
        Component: UserOverview,
        index: true,
      },
      {
        Component: UserDetails,
        loader: userDetailsLoader,
        path: ':id',
      },
    ],
    path: 'users',
  },

  // Adapt
  {
    children: [
      {
        children: [
          {
            Component: AdaptLaneOverview,
            index: true,
          },
          {
            Component: LaneExplorerView,
            path: ':id/explorer',
          },
        ],
        path: 'lanes',
      },
      {
        children: [
          { Component: AdaptNetworkMoveOverview, index: true },
          {
            Component: AdaptNetworkMoveDetails,
            loader: adaptNetworkMoveDetailsLoader,
            path: ':id',
          },
        ],
        path: 'network-moves',
      },
      {
        children: [
          { Component: AdaptConstellationOverview, index: true },
          {
            Component: AdaptConstellationDetails,
            loader: adaptConstellationDetailsLoader,
            path: ':id',
          },
        ],
        path: 'constellations',
      },
    ],
    path: 'adapt',
  },

  // Analytics
  {
    children: [
      {
        Component: AnalyticsLaneOverview,
        path: 'lanes',
      },
    ],
    path: 'analytics',
  },

  // Planning
  {
    children: [
      {
        children: [
          {
            Component: LanePlanOverview,
            index: true,
          },
          {
            Component: LanePlanDetails,
            loader: lanePlanDetailsLoader,
            path: ':id',
          },
        ],
        path: 'plans',
      },
      {
        loader: () => redirect('https://admin.leaflogistics.com/lane-management'),
        path: 'lanes',
      },
    ],
    path: 'planning',
  },

  // Contracts
  {
    children: [
      {
        Component: ContractOverview,
        index: true,
      },
      {
        children: [
          {
            Component: ContractDetails,
            index: true,
            loader: contractDetailsLoader,
          },
          {
            Component: ContractDetailsShipmentsDetails,
            loader: shipmentDetailsLoader,
            path: 'shipments/:shipmentId',
          },
        ],
        path: ':contractId',
      },
    ],
    path: 'contracts',
  },

  {
    loader: () => redirect('https://admin.leaflogistics.com/contract-matching'),
    path: '/contracts/matching',
  },

  // Execution
  {
    children: [
      {
        children: [
          {
            Component: ShipmentOverview,
            index: true,
          },
          {
            Component: ContractDetailsShipmentsDetails,
            loader: shipmentDetailsLoader,
            path: ':shipmentId',
          },
        ],
        path: 'shipments',
      },
      {
        children: [
          {
            Component: ExecutionLaneOverview,
            index: true,
          },
          {
            Component: ExecutionLaneDetails,
            loader: executionLaneDetailsLoader,
            path: ':id',
          },
        ],
        path: 'lanes',
      },
      {
        children: [
          {
            Component: ExecutionLocationOverview,
            index: true,
          },
          {
            Component: ExecutionLocationDetails,
            loader: executionLocationDetailsLoader,
            path: ':id',
          },
        ],
        path: 'locations',
      },
      {
        Component: FuelPriceOverview,
        path: 'fuel-prices',
      },
      {
        Component: FuelPriceIndexOverview,
        path: 'fuel-price-index',
      },
    ],
    path: 'execution',
  },

  // Tools
  {
    children: [
      {
        Component: PCM,
        path: 'pcm',
      },
      {
        Component: ShipperNetwork,
        path: 'shipper-network',
      },
      {
        Component: ContractNetwork,
        path: 'contract-network',
      },
    ],
    path: 'tools',
  },

  // Settings
  {
    children: [
      {
        Component: EquipmentTypeOverview,
        index: true,
      },
      {
        Component: EquipmentTypeDetails,
        loader: equipmentTypeDetailsLoader,
        path: ':id',
      },
    ],
    path: 'equipment-types',
  },
  {
    children: [
      {
        Component: FuelSurchargeScheduleOverview,
        index: true,
      },
      {
        Component: FuelSurchargeScheduleDetails,
        loader: fuelSurchargeDetailsLoader,
        path: ':id',
      },
    ],
    path: 'fuel-surcharge-schedules',
  },
]

const router = Sentry.createRouter([
  ...publicRoutes,
  {
    Component: LayoutPage,
    ErrorBoundary: ErrorPage,
    children: [
      {
        Component: NotFoundPage,
        path: '*',
      },
      {
        ErrorBoundary: ErrorPage,
        children: privateRoutes,
      },
      ...redirectRoutes,
    ],
    id: 'root',
    path: '/',
  },
])

export { router }
